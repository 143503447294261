export const pricing_list = [
    {
        label: 'Likes',
        free: '3/day',
        premium: 'Unlimited',
        border_gold: true,
        icon: '👍🏼',
    },
    {
        label: 'Super Likes',
        free: '1/day',
        premium: '5/day',
        border_gold: true,
        icon: '⭐️',
    },
    {
        label: 'Unlimited Profile Views!',
        free: 'Yes',
        premium: 'Yes',
        icon: '🔎',
    },
    {
        label: 'Unlimited Chat Access',
        free: 'Yes',
        premium: 'Yes',
        icon: '💬',
    },
    {
        label: 'Free Candidate Recommendation',
        free: 'Yes',
        premium: 'Yes',
        icon: '📑',
    },
    {
        label: 'Company Culture Showcase',
        free: 'Yes',
        premium: 'Yes',
        icon: '📸',
    },
    {
        label: 'Featured Employer Card',
        free: 'No',
        premium: 'Yes',
        icon: '🎖️',
    },
    {
        label: 'Who Liked Your Job',
        free: 'No',
        premium: 'Yes',
        icon: '❤️',
    },
    {
        label: 'Stealth Mode',
        free: 'No',
        premium: 'Yes',
        icon: '👀',
    },
    {
        label: '1 Job Fair every month',
        free: 'No',
        premium: 'Yes',
        icon: '💼',
    },
    {
        label: 'Undo Swipe',
        free: 'No',
        premium: 'Yes',
        icon: '↩️',
    },
    {
        label: 'Unlimited Job Posts',
        free: 'Yes',
        premium: 'Yes',
        icon: '📌',
    },
]
