import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import jobFairImg1 from '../../assets/img/jobFairImg1.png'
import jobFairImg2 from '../../assets/img/jobFairImg2.png'
import jobFairImg3 from '../../assets/img/jobFairImg3.png'
import LogoWTag from '../../assets/img/logo-w-tag.svg'
import CustomTooltip from '../../components/reusables/CustomTooltip'
import appActions from '../../redux/app/actions'
import jobfairActions from '../../redux/job-fair/jobfairActions'
import { jobFairRequest } from '../../service/requests'
import { JobFairInterestModal } from './JobFairModals'
import './styles.css'
function RegistrationSuccessModal({ show, jobfair }) {
    const navigate = useNavigate()
    return (
        <Modal show={show} centered>
            <div className="p-4">
                <h6 className="text-center">Success</h6>
                <p className="text-center text-disabled">
                    You have registered for the career fair. Get ready for the
                    event by <br /> updating your job postings or creating a new
                    one!
                </p>
                <div className="d-flex justify-content-center mt-4">
                    <button className="btn btn-outline-primary me-2">
                        Go to My jobs
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => navigate('/create-job')}
                    >
                        + Create a Job
                    </button>
                </div>
                <p
                    role="button"
                    className="text-center mt-3 text-decoration-underline text-disabled"
                    onClick={() =>
                        navigate(`/job-fair/dashboard/${jobfair?._id}`, {
                            state: {
                                jobfair: jobfair,
                            },
                        })
                    }
                >
                    Back to Dashboard
                </p>
            </div>
        </Modal>
    )
}

function JobFairRegister(props) {
    const { JobFairs, setSignInToFair, setShowNav } = props
    const navigate = useNavigate('/')
    const [regCode, setRegCode] = useState('')
    const [singleJobFair, setSingleJobFair] = useState(null)
    const [error, setError] = useState(null)
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    //interested
    const [showModal, setShowModal] = useState(false)

    const { id } = useParams()
    useEffect(() => {
        if (!!id) {
            const jf = _.find(JobFairs?.jobfairs?.data, { _id: id })
            setSingleJobFair(jf)
        }
    }, [id, JobFairs])

    useEffect(() => {
        try {
            // jobFairRequest.
        } catch (err) {
            console.log(err)
        }
    })

    let regDate = ''
    let startDate = moment(singleJobFair?.startDate)
    let endDate = moment(singleJobFair?.endDate)
    if (
        startDate.isSame(endDate, 'year') &&
        startDate.isSame(endDate, 'month')
    ) {
        startDate = startDate.format('MMMM DD - ')
        endDate = endDate.format('DD, YYYY')
        regDate = `${startDate} - ${endDate}`
    } else {
        regDate = `${startDate.format('MMMM DD, YYYY')} - ${endDate.format(
            'MMMM DD, YYYY'
        )}`
    }
    useEffect(() => {
        setShowNav(false)
    }, [])

    const handleRegister = () => {
        if (singleJobFair) {
            try {
                let payload = {
                    jobFairCode: regCode,
                    jobFairId: singleJobFair?._id,
                }
                jobFairRequest
                    .registerAndEnterJobFair(payload)
                    .then((res) => {
                        const { ongoing, alreadyRegistered } = res.data
                        if (!!ongoing && !!alreadyRegistered) {
                            console.log('alreadyyy')
                            setSignInToFair(singleJobFair?._id)
                            navigate(
                                `/job-fair/dashboard/${singleJobFair?._id}`
                            )
                        }
                        if (!alreadyRegistered) {
                            setShowSuccessModal(true)
                        }
                    })

                    .catch((err) => {
                        setError('Invalid Code. Try again')
                    })
                    .finally(() => {
                        //do alert
                        setTimeout(() => {
                            setError(null)
                        }, 3000)
                    })
            } catch (err) {
                console.log(err)
                setError('Invalid Code')
            }
        }
    }
    return (
        <div className="row vh-100">
            <div
                className="d-none d-md-block left-0 col-md-4 d-none d-md-flex justify-content-center align-items-center flex-column p-0 h-100"
                style={{
                    background: singleJobFair?.brandColor,
                }}
            >
                <h4 className="fs24 text-white">
                    Joblu x {singleJobFair?.partnerName}
                </h4>
                <h2 className="text-white fs36 fw600 mb-3">{}</h2>
                <h6 className="text-white fs18 mb-5">{regDate}</h6>

                <div className="px-4">
                    <div className="mb-5 align-items-center row">
                        <div className="col-12 col-sm-4">
                            <img
                                className="me-4"
                                src={jobFairImg1}
                                width=""
                                alt=""
                            />
                        </div>
                        <div className="col-12 col-sm-8 px-0">
                            <p className="text-white">
                                Find your match and hire with just
                                <br />a few swipes.
                            </p>
                        </div>
                    </div>
                    <div className="mb-5 align-items-center row">
                        <div className="col-12 col-sm-4">
                            <img
                                className="me-4"
                                src={jobFairImg2}
                                width=""
                                alt=""
                            />
                        </div>
                        <div className="col-12 col-sm-8 px-0">
                            <p className="text-white">
                                Showcase your company and get
                                <br />
                                noticed by the best of the best.
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center ">
                        <div className="col-12 col-sm-4 d-flex justify-content-end">
                            <img
                                className=""
                                src={jobFairImg3}
                                width=""
                                alt=""
                            />
                        </div>
                        <div className="col-12 col-sm-8 px-0">
                            <p className="text-white px-0">
                                Get notified on the go and never miss
                                <br />
                                out on a golden opportunity.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="col-md-8 col-sm-12 d-flex flex-column align-items-center justify-content-center h-100 nsb"
                style={{ overflowY: 'scroll' }}
            >
                <div
                    role="button"
                    onClick={() => {
                        navigate(-1)
                    }}
                    className="w-100 p-3"
                >
                    <CustomTooltip title="Go back" placement="left">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="currentColor"
                            class="bi bi-arrow-left"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                            />
                        </svg>
                    </CustomTooltip>{' '}
                    Go back
                </div>
                <div
                    className="d-flex flex-column align-items-center justify-content-center h-100 nsb w-100"
                    style={{ overflowY: 'scroll' }}
                >
                    <div className="d-flex align d-block d-md-none mt-5">
                        <img className="mb-4" src={LogoWTag} width="150" />
                    </div>
                    <div className="row w-100">
                        <div className="col-md-10 col-sm-12">
                            <h3 className="fs30 mb-5">Career Fair 2022</h3>
                            <div className="mb-4">
                                <div>
                                    <h4 className="fs24">
                                        Enter Registration Code
                                    </h4>
                                </div>
                            </div>

                            <div className="mb-4">
                                <input
                                    id="jobFairCode"
                                    className="form-control bg-transparent border-0 border-bottom input-code"
                                    autocomplete="off"
                                    placeholder="00000-00000"
                                    maxlength="20"
                                    onChange={(e) => {
                                        setRegCode(e.target.value)
                                    }}
                                />
                                {!!error && (
                                    <span className="text-danger small error-message">
                                        Job Fair Code is not valid
                                    </span>
                                )}
                            </div>
                            <div className="mb-5">
                                <button
                                    type="submit"
                                    className="btn btn-primary border-0 btn-lg w-136 fs14 text-center"
                                    onClick={() => {
                                        handleRegister()
                                    }}
                                    disabled={!regCode}
                                >
                                    Register
                                </button>
                                <span className="d-none d-lg-inline">
                                    {' '}
                                    or press Enter ↵
                                </span>
                            </div>
                            <div className="d-flex mb-3">
                                <p>
                                    Visit our
                                    <a
                                        href="https://joblu.io/career-fairs-faqs/"
                                        target="_blank"
                                    >
                                        <u> FAQs </u>
                                    </a>{' '}
                                    page to learn more
                                </p>
                            </div>
                            <div>
                                <p
                                    className="c-pointer"
                                    data-toggle="modal"
                                    data-target="#registerModal"
                                    role="button"
                                    onClick={() => {
                                        setShowModal(!showModal)
                                    }}
                                >
                                    <u>
                                        Are you interested to have your company
                                        be part of a career fair?
                                    </u>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RegistrationSuccessModal
                show={showSuccessModal}
                jobfair={singleJobFair}
            />
            <JobFairInterestModal
                show={showModal}
                setShowModal={setShowModal}
                jobfairs={JobFairs.jobfairs}
            />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        JobFairs: state.JobFairs,
    }
}
const mapDispatchToProps = {
    setSignInToFair: jobfairActions.setSignIn,
    setShowNav: appActions.setShowMainNav,
}
export default connect(mapStateToProps, mapDispatchToProps)(JobFairRegister)
