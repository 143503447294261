import TextField from '../../../components/inputs/CustomTextField'

function WizardStep2(props) {
    const { profile, setProfile, errors } = props

    return (
        <div id="wizard-steps">
            <h2 className="mb-3">Company Website</h2>
            <TextField
                type="text"
                className="form-control border-0"
                id=""
                aria-describedby=""
                autoComplete={'off'}
                placeholder="Enter your company's website (https://joblu.io)"
                onChange={(e) => {
                    setProfile({ ...profile, companyWebsite: e.target.value })
                }}
                defaultValue={profile?.companyWebsite}
                error={errors?.website}
                // style={{border:0}}
            />
        </div>
    )
}

export default WizardStep2
