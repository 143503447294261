function WizardStep4(props) {
    const { profile, setProfile } = props
    return (
        <div id="wizard-steps">
            <h2 className="mb-3">Number of Employees</h2>
            <input
                type="text"
                className="form-control border"
                id=""
                aria-describedby=""
                autoComplete={'off'}
                defaultValue={profile.companySize}
                onChange={(e) => {
                    setProfile({
                        ...profile,
                        companySize: e.target.value,
                    })
                }}
            />
        </div>
    )
}

export default WizardStep4
