import { useNavigate } from 'react-router-dom'
import cardImg from '../../assets/img/pricing-page/forExplore.svg'
import { defaultActions } from '../../views/explore/Explore'
const CardInExplore = (props) => {
    const { onSwipeFulfilled, index } = props
    const navigate = useNavigate()
    return (
        <div className="row mx-0 p-0" {...props}>
            <div className="d-none d-sm-block col-6 p-0">
                <img
                    src={cardImg}
                    alt=""
                    style={{ width: '100%', height: '100%' }}
                />
            </div>
            <div className="col-12 col-sm-6">
                <div className="d-flex h-100">
                    <div className="p-3 m-auto">
                        <h4 className="text-primary text-center ">
                            Connect to more candidates
                        </h4>
                        <p className="text-center ">
                            Save thousand in recruitment cost and source <br />{' '}
                            the right candidate the fast, fun, and <br />
                            fabulous way!{' '}
                        </p>

                        <button
                            className="btn btn-primary text-center w-100 mt-4"
                            onClick={() => {
                                navigate('/pricing')
                            }}
                        >
                            Go Premium Now, Get 50% OFF!
                        </button>
                        <button
                            className="btn btn-outline-primary text-center w-100 mt-2"
                            onClick={() => {
                                onSwipeFulfilled(
                                    {},
                                    index,
                                    defaultActions.left,
                                    'slide-out-left',
                                    'shrinked'
                                )
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardInExplore
