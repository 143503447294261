import ExpandedCard from './ExpandedCard'
import NoJobSeekersFound from './NoJobSeekersFound'
function ExpandedExplore(props) {
    const { jobSeekers, handleFilter } = props
    let showingIndex = 0

    return (
        // FIX REPONSIVENESS HERE
        <div
            className="col-12 col-md-11 position-relative"
            style={{ minHeight: 500 }}
        >
            {!!jobSeekers.length ? (
                jobSeekers.map((data, index) => {
                    showingIndex = index
                    return (
                        <div
                            className={` mt-3`}
                            style={{
                                width: '100%',
                                right: 0,
                                left: 0,
                                display:
                                    index === jobSeekers.length - 1
                                        ? 'block'
                                        : 'none',
                            }}
                            id={`card-expanded-${index}`}
                        >
                            <ExpandedCard
                                jobseeker={data}
                                showingIndex={showingIndex}
                                index={index}
                                {...props}
                            />
                        </div>
                    )
                })
            ) : (
                <NoJobSeekersFound
                    className="mx-auto"
                    getJobSeekers={handleFilter}
                />
            )}
        </div>
    )
}

export default ExpandedExplore
