import Fuse from 'fuse.js'
import { useEffect, useState } from 'react'
import SelectSearch from 'react-select-search'
import { jobsRequest } from '../../service/requests'
import { titleCase } from '../../utils/helpers'
import './react-select.css'
export function myFuzzySearch(options) {
    const fuse = new Fuse(options, {
        keys: ['name', 'groupName', 'items.name'],
        threshold: 0.3,
    })

    return (value) => {
        // console.log('vallllll', value)
        if (!value.length) {
            return options
        }

        return fuse?.search(value)?.map(({ item }) => item)
    }
}
function SelectJobPost({ onChange, value, placeholder }) {
    const [jobsList, setJobsList] = useState([])
    const [displayOptions, setDisplayOptions] = useState(false)
    const showHide = (onBlur) => {
        if (onBlur) {
            setDisplayOptions(false)
        } else {
            setDisplayOptions(!displayOptions)
        }
    }

    useEffect(() => {
        try {
            jobsRequest.getJobsForLists().then((res) => {
                if (res) {
                    setJobsList(res?.data?.jobs)
                } else {
                    console.log('erro fetching jobs', res)
                }
            })
        } catch (err) {
            console.log(err)
        }
    }, [])

    let options = jobsList.map((job) => ({
        name: titleCase(job?.label),
        value: job?.value,
    }))

    // options = [
    //     { name: value === null ? placeholder : 'All job post', value: '' },
    //     ...options,
    // ]

    return (
        <div onClick={() => showHide()} onBlur={() => showHide(true)}>
            <SelectSearch
                printOptions={displayOptions ? 'always' : 'never'}
                options={options}
                search
                filterOptions={myFuzzySearch}
                emptyMessage={() => (
                    <div style={{ textAlign: 'center', fontSize: '0.8em' }}>
                        {!!options ? 'Not found' : 'No job post'}
                    </div>
                )}
                placeholder={placeholder || 'Select Job Post'}
                onChange={onChange}
                value={value}
            />
        </div>
    )
}

export default SelectJobPost
