import jobfairActions from './jobfairActions'

export const initJobFair = {
    jobfairs: [],
    signedInJobFair: null,
    showBanner: true,
}

const jobfairReducer = (state = initJobFair, action) => {
    switch (action.type) {
        case jobfairActions.SET_JOB_FAIRS:
            return {
                ...state,
                jobfairs: action.payload,
            }
        case jobfairActions.SET_JOB_FAIR_SIGNIN:
            return {
                ...state,
                signedInJobFair: action.payload,
            }
        case jobfairActions.SET_SHOW_BANNER:
            return {
                ...state,
                showBanner: action.payload,
            }
        default:
            return state
    }
}

export default jobfairReducer
