import moment from 'moment'
import { useNavigate } from 'react-router-dom'
export const JobFairBanner = ({ jobfairs, show, setShowBanner }) => {
    const navigate = useNavigate()
    if (!show) return null
    const jobfair = jobfairs?.data?.[0]

    let { brandColor, textColor, partnerName, registrationDate, _id } = jobfair
        ? jobfair
        : {}
    return (
        <>
            {jobfairs.totalCount !== 0 && jobfairs?.totalCount === 1 ? (
                <div
                    className="col-12"
                    style={{
                        background: brandColor,
                    }}
                >
                    <div className="d-flex justify-content-between align-items-center text-white py-2">
                        <div className="d-flex container justify-content-center">
                            <p
                                className="my-auto"
                                style={{
                                    color: textColor,
                                }}
                            >
                                Registrations for the 2022 Joblu x {partnerName}{' '}
                                Career Fair is still open! Register by{' '}
                                {moment(registrationDate).format(
                                    ' MMMM DD, YYYY '
                                )}
                                to secure a spot.
                            </p>
                            <button
                                className="btn btn-primary mx-3"
                                onClick={() =>
                                    navigate(`/job-fair/register/${_id}`)
                                }
                            >
                                Register Now
                            </button>
                        </div>
                        <div className="d-flex justify-content-end flex-grow-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="white"
                                className="my-auto bi bi-x ms-5"
                                viewBox="0 0 16 16"
                                role="button"
                                onClick={() => {
                                    setShowBanner(false)
                                }}
                            >
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="col-12 bg-dark text-center py-2 d-flex row">
                    <div className="col-11 d-flex justify-content-center">
                        <p className="text-white my-auto text-center">
                            Sign-up and explore our upcoming Career Fairs this
                            2022!
                        </p>
                        <button
                            onClick={() => {
                                navigate('/job-fair/events')
                            }}
                            className="ms-3 btn btn-primary"
                        >
                            Sign Up
                        </button>
                    </div>
                    <div className="col-1 d-flex justify-content-end flex-grow-1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="white"
                            className="my-auto bi bi-x ms-5"
                            viewBox="0 0 16 16"
                            role="button"
                            onClick={() => {
                                setShowBanner(false)
                            }}
                        >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                </div>
            )}
        </>
    )
}
