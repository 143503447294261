import { useEffect, useState } from 'react'
import { download_profile_mobile } from '../../utils/download-profiles'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Loading from '../../components/reusables/Loading'

const DownloadProfileApplicant = (props) => {
    const [isDownloading, setIsDownloading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        const token = searchParams.get("token")
        download_profile_mobile((status) => {
            status === 'downloading'
                ? setIsDownloading(true)
                : setIsDownloading(false)
            window.close()
        }, token)
    }, [])

    return (
        <div style={{alignSelf: 'center', justifyContent: 'center', display: 'flex', height: '100vh'}}>
            <Loading show={isDownloading} />
        </div>
    )
}

export default DownloadProfileApplicant
