import { all, put, takeEvery } from 'redux-saga/effects';
import { jobMatchRequest } from '../../service/requests';
import action from './action';

function* getJobMatchesRequest() {
  try {
    const response = yield jobMatchRequest.getJobMatches();
    if (response) {
      yield put({
        type: action.GET_JOB_MATCHES_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.GET_JOB_MATCHES_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log('getJobMatchesRequest : error : ', error);
    console.log(error.response.data)
    yield put({
      type: action.GET_MY_LIKED_CANDIDATES_FAILURE,
      payload: {},
    });
  }
}

function* getMyLikedCandidatesRequest({payload}) {
  try {
    const response = yield jobMatchRequest.getMyLikedCandidates(payload);
    if (response) {
      yield put({
        type: action.GET_MY_LIKED_CANDIDATES_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.GET_MY_LIKED_CANDIDATES_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log('getMyLikedCandidates : error : ', error);
    console.log(error.response.data)
    yield put({
      type: action.GET_MY_LIKED_CANDIDATES_FAILURE,
      payload: {},
    });
  }
}

function* getLikedMyJobsRequest({payload}) {
  try {
    const response = yield jobMatchRequest.getLikedMyJobs(payload);
    if (response) {
      yield put({
        type: action.GET_LIKED_MY_JOBS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.GET_LIKED_MY_JOBS_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log('getLikedMyJobsRequest : error : ', error);
    console.log(error.response.data)
    yield put({
      type: action.GET_LIKED_MY_JOBS_FAILURE,
      payload: {},
    });
  }
}



export default function* rootSaga() {
  yield all([takeEvery(action.GET_JOB_MATCHES_REQUEST, getJobMatchesRequest)]);
  yield all([takeEvery(action.GET_MY_LIKED_CANDIDATES_REQUEST, getMyLikedCandidatesRequest)]);
  yield all([takeEvery(action.GET_LIKED_MY_JOBS_REQUEST, getLikedMyJobsRequest)]);
}
