import buttonDismiss from './joblu-2.0/img/button_dismiss.svg'
import closeIcon from './joblu-2.0/img/dismiss.png'
import logout from './joblu-2.0/img/logout.svg'
import dashboardFilled from './joblu-2.0/img/nav-icons/active/dashboard-filled.svg'
import jobFilled from './joblu-2.0/img/nav-icons/active/jobs-filled.svg'
import likesFilled from './joblu-2.0/img/nav-icons/active/likes-filled.svg'
import messagesFilled from './joblu-2.0/img/nav-icons/active/messages-filled.svg'
import notificationsFilled from './joblu-2.0/img/nav-icons/active/notifications-filled.svg'
import resumeFilled from './joblu-2.0/img/nav-icons/active/resume-filled.svg'
import notifications from './joblu-2.0/img/nav-icons/bell-icon.svg'
import cards from './joblu-2.0/img/nav-icons/cards-icon.svg'
import jobs from './joblu-2.0/img/nav-icons/jobs-icon.svg'
import likesicon from './joblu-2.0/img/nav-icons/likes-icon.svg'
import message from './joblu-2.0/img/nav-icons/message-icon.svg'
import resume from './joblu-2.0/img/nav-icons/resume-icon.svg'
import toggleMenu from './joblu-2.0/img/nav-icons/toggle-menu-icon.svg'
import settings2 from './joblu-2.0/img/settings2.svg'
export const JobluIconsV2 = {
    toggleMenu: toggleMenu,
    dashboardFilled: dashboardFilled,
    cards: cards,
    jobs: jobs,
    jobFilled: jobFilled,
    likesFilled: likesFilled,
    likesicon: likesicon,
    messagesFilled: messagesFilled,
    message: message,
    resume: resume,
    resumeFilled: resumeFilled,
    notifications: notifications,
    notificationsFilled: notificationsFilled,
    settings2: settings2,
    logout: logout,
    buttonDismiss: buttonDismiss,
    closeIcon: closeIcon,
}
