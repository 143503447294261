import action from './action';

export const initState = {
  isFetching: false,
  isFetched: false,
  notificationsList: {
    totalCount: 0,
    totalUnread: 0,
    page: 0,
    limit: 10,
    data: []
  },
};

const notificationsReducer = (state = initState, act) => {
  switch (act.type) {
    case action.GET_NOTIFICATIONS_REQUEST:
      console.log('NOTIFICATIONS REDUCER :: GET_NOTIFICATIONS_REQUEST');
      return {
        ...state,
        isFetching: true,
        isFetched: false,
      };
    case action.GET_NOTIFICATIONS_SUCCESS:
      console.log('NOTIFICATIONS REDUCER :: GET_NOTIFICATIONS_SUCCESS');
      if(act.payload.page > 1){
        const currentList = state.notificationsList.data
        let newList = act.payload
        newList.data = [...currentList, ...newList.data]
        return {
          ...state,
          isFetched: true,
          isFetching: false,
          notificationsList: newList,
        };
      }
      else{
        return {
          ...state,
          isFetched: true,
          isFetching: false,
          notificationsList: act.payload,
        };
      }

    case action.GET_NOTIFICATIONS_FAILURE:
      console.log('NOTIFICATIONS REDUCER :: GET_NOTIFICATIONS_FAILURE');
      return {
        ...state,
        isFetched: false,
        isFetching: false,
      };
    case action.SOFT_RESET:
      console.log('NOTIFICATIONS REDUCER :: SOFT_RESET');
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};


export default notificationsReducer;