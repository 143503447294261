import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha as GoogleReCaptcha_
  } from 'react-google-recaptcha-v3';
import { creds } from '../../utils/constants'



const GoogleReCaptcha = (props) => {
    const { handleVerify } = props


    return (
    <GoogleReCaptchaProvider reCaptchaKey={creds?.GOOGLE_RECAPTCHA_KEY}>
        <GoogleReCaptcha_ onVerify={handleVerify} />
    </GoogleReCaptchaProvider>
    )
    
};


export default GoogleReCaptcha
