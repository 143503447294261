import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Select from '../../components/inputs/CustomSelect'
import { store } from '../../redux/store'
import { jobFairRequest } from '../../service/requests'
export const LeaveModal = ({ show, setSignedInJobFair }) => {
    const [open, setOpen] = useState(show)
    const navigate = useNavigate()

    useEffect(() => {
        setOpen(show)
    }, [show])

    const handleSubscribe = () => {
        //
    }

    return (
        <Modal centered show={open} onHide={() => setOpen(false)}>
            <div className="p-4">
                <h6 className="text-center">Oh no, Leaving so soon ?</h6>
                <p className="text-center open-sans">
                    Well, we,ll just wait for you until you come back.
                    <br /> You're welcome anytime!
                </p>
                {/* <div className="d-flex mt-3 justify-content-center">
                    <div className="w-75">
                        <TextField
                            placeholder="Enter your email address"
                            type="email"
                            fullWidth
                            className="open-sans"
                        />
                    </div>
                    <button
                        onClick={() => handleSubscribe()}
                        className="btn btn-primary"
                    >
                        Subscribe
                    </button>
                </div> */}
                <div className="row mt-4 open-sans">
                    <div className="col-12 col-md-6 mb-1 px-1">
                        <button
                            className="btn btn-primary text-center w-100"
                            style={{ width: 200 }}
                            onClick={() => {
                                setOpen(false)
                            }}
                        >
                            Return to Career Fair
                        </button>
                    </div>
                    <div className="col-12 col-md-6 mb-1 px-1">
                        <button
                            className="btn btn-outline-primary me-2 text-center w-100"
                            style={{ width: 200 }}
                            onClick={() => {
                                setSignedInJobFair(null)
                                setOpen(false)
                                navigate('/dashboard')
                            }}
                        >
                            Leave
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export const JobFairInterestModal = ({
    show,
    setSignedInJobFair,
    jobfairs,
}) => {
    const [open, setOpen] = useState(show)
    const User = store.getState().User
    const navigate = useNavigate()
    const [jobfairSelectOptions, setJobFairsOptions] = useState([])
    const [selectedCountries, setSelectedCountries] = useState([])
    const [payload, setPayload] = useState({
        name: `${User?.user?.firstName} ${User?.user?.lastName}`,
        email: `${User?.user?.email}`,
        companyName: `${User?.user?.companyName}`,
        jobTitle: '',
        jobFairName: '',
    })
    useEffect(() => {
        setOpen(show)
    }, [show])

    useEffect(() => {
        try {
            jobFairRequest.getOngoingJobFairs().then((res) => {
                if (res) {
                    const options = _.map(res.data?.data, (obj) => {
                        return {
                            label: obj.name,
                            value: obj.name,
                        }
                    })
                    setJobFairsOptions(options)
                    setPayload({
                        ...payload,
                        jobFairName: options?.[0]?.value,
                    })
                }
            })
        } catch (err) {
            console.log(err)
        }
    }, [])

    const fields = [
        {
            name: 'Name',
            type: 'text',
            placeholder: 'Name',
            col_md: 12,
            defaultValue: `${User?.user?.firstName} ${User?.user?.lastName}`,
            required: true,
        },
        {
            name: 'email',
            type: 'email',
            placeholder: 'Email',
            required: true,
            col_md: 12,
            defaultValue: `${User?.user?.email}`,
        },
        {
            name: 'companyName',
            type: 'text',
            placeholder: 'Company Name',
            required: true,
            col_md: 12,
            defaultValue: `${User?.user?.companyName}`,
        },
        {
            name: 'jobTitle',
            type: 'text',
            placeholder: 'Job Title',
            required: true,
            col_md: 12,
        },
    ]

    const handleSubmit = (e) => {
        e.preventDefault()
        try {
            jobFairRequest
                .sendJobFairInterest(payload)
                .then((res) => {
                    console.log(res)
                })
                .finally(() => {
                    setOpen(false)
                })
        } catch (err) {
            console.log(err)
        }
    }
    return (
        <Modal centered show={open} onHide={() => setOpen(false)}>
            <form className="p-3" onSubmit={handleSubmit} noValidate={false}>
                <h4 className="text-center">Career Fair Interest Form</h4>
                <div className="mt-3 row p-0 m-0">
                    {fields.map((field) => {
                        return (
                            <div
                                className={`col-12  col-md-${field.col_md} mt-2 px-0 px-1`}
                            >
                                <input
                                    type={field.type}
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    className="form-control border border-dark open-sans"
                                    onChange={(e) => {
                                        setPayload({
                                            ...payload,
                                            [field.name]: e.target.value,
                                        })
                                    }}
                                    defaultValue={field?.defaultValue}
                                    required={field?.required}
                                />
                            </div>
                        )
                    })}
                </div>
                <div className="mt-3 px-1">
                    <label className="open-sans">
                        Which Career Fair are you interested in?
                    </label>
                    <Select
                        options={jobfairSelectOptions}
                        className="border border-dark"
                        onChange={(e) => {
                            setPayload({
                                ...payload,
                                jobFairName: e.target.value,
                            })
                        }}
                    />
                </div>
                {/* <div className="mt-3 px-1">
                    <label className="open-sans">
                        Which countries are you looking to hire?
                    </label>
                    <SelectCountry2
                        noCellCode
                        grouped
                        wrapperClass="border border-dark"
                        noAll
                        onChange={(val) => {
                            console.log(val)
                            let countries = [...selectedCountries, val]
                            countries = _.uniq(countries)
                            setSelectedCountries(countries)
                        }}
                    />
                    <div className="mt-1">
                        <CustomChip
                            variant="warning"
                            data={selectedCountries}
                            onDelete={(index) => {
                                console.log(index)
                                let data = selectedCountries
                                data.splice(index, 1)
                                setSelectedCountries([...data])
                            }}
                        />
                    </div>
                </div> */}

                <div className="d-flex justify-content-center mt-5">
                    <button
                        className="btn btn-primary text-center"
                        style={{ width: 136 }}
                        type="submit"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </Modal>
    )
}
