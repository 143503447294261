import React from "react";

function PaymentAndCredits() {
  return (
    <div className="row">
      <div className="col-md-7 mb-3 col-sm-12">
        <div className="card">
          <div className="card-header h6 fs18 text-primary bg-white">
            Likes and Super Likes
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <h2 className="fs36">10</h2>
                <p>Likes left for today</p>
              </div>
              <div className="col-md-6 col-sm-12">
                <h2 className="fs36">10</h2>
                <p>Super Likes left for this month</p>
              </div>
              <div className="col-md-12 col-sm-12 mt-3">
                {/* <a href=""></a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-5 mb-3 col-sm-12">
        <div className="card">
          <div className="card-header text-primary bg-white">
            <h6 className="fs18 mb-0">Payment Methods</h6>
          </div>
          <div className="card-body">
            <p className="mb-3 d-none">
              Discover your next talent! Get exclusive discounts & offers when
              you connect your bank account, stripe, or credit card.
            </p>
            <button className="btn btn-primary w-136 d-none">Add</button>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="option1"
              />
              <label className="form-check-label me-3" htmlFor="exampleRadios1">
                Stripe Account
              </label>
              <button className="btn-outline-primary rounded-pill btn-small fs13">
                Connected
              </button>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios2"
                value="option1"
              />
              <label
                className="form-check-label me-3"
                htmlFor="exampleRadios1"
              ></label>
            </div>
            <button className="btn btn-outline-primary w-136">Edit</button>
          </div>
        </div>
      </div>
      <div className="col-md-12 mb-3">
        <div className="card">
          <div className="card-header text-primary bg-white">
            <h6 className="fs18 mb-0">Account Plan</h6>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <h3 className="fs30">Starter</h3>
              </div>
              <div className="col-md-6 col-sm-12 text-right">
                <button className="btn btn-outline-primary">
                  Downgrade Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-header text-primary bg-white">
            <div className="row">
              <div className="col-md-6 col-sm-12 d-flex">
                <h6 className="fs18 mb-0">Billing and Invoice</h6>
              </div>
              <div className="col-md-6 col-sm-12">
                <select name="" id="" className="form-control">
                  <option value="">Select Month</option>
                </select>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <td>01/01/2022 - 02/01/2022</td>
                    <td>Ref No. 039573204</td>
                    <td>Professional Plan</td>
                    <td>
                      <span className="badge badge-pill badge-danger">
                        Primary
                      </span>
                    </td>
                    <td>#</td>
                  </tr>
                  <tr>
                    <td>01/01/2022 - 02/01/2022</td>
                    <td>Ref No. 039573204</td>
                    <td>Professional Plan</td>
                    <td>
                      <span className="badge badge-pill badge-warning">
                        Primary
                      </span>
                    </td>
                    <td>#</td>
                  </tr>
                  <tr>
                    <td>01/01/2022 - 02/01/2022</td>
                    <td>Ref No. 039573204</td>
                    <td>Professional Plan</td>
                    <td>
                      <span className="badge badge-pill badge-outline-primary">
                        Primary
                      </span>
                    </td>
                    <td>#</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PaymentAndCredits;
