import { all, put, takeEvery } from 'redux-saga/effects';
import { jobApplicantRequest, jobFairRequest } from '../../service/requests';
import action from './action';

function* getJobApplicantRequest({ payload }) {
  try {
    const response = yield jobApplicantRequest.getJobApplicants(payload);
    if (response) {
      yield put({
        type: action.GET_JOB_APPLICANTS_SUCCESS,
        payload: response.data.applicants,
      });
    } else {
      yield put({
        type: action.GET_JOB_APPLICANTS_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log('getJobApplicantsRequest : error : ', error);
  }
}

function* getJobApplicantActiveRequest({ payload }) {
  try {
    const response = yield jobApplicantRequest.getJobApplicants_Active(payload);
    if (response) {
      yield put({
        type: action.GET_JOB_APPLICANTS_ACTIVE_SUCCESS,
        payload: response.data.applicants,
      });
    } else {
      yield put({
        type: action.GET_JOB_APPLICANTS_ACTIVE_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: action.GET_JOB_APPLICANTS_ACTIVE_FAILURE,
      payload: {},
    });
    console.log('getJobApplicantActiveRequest : error : ', error);
  }
}

function* getJobApplicantShortlistedRequest({ payload }) {
  try {
    const response = yield jobApplicantRequest.getJobApplicants_Shortlisted(payload);
    if (response) {
      yield put({
        type: action.GET_JOB_APPLICANTS_SHORTLISTED_SUCCESS,
        payload: response.data.applicants,
      });
    } else {
      yield put({
        type: action.GET_JOB_APPLICANTS_SHORTLISTED_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: action.GET_JOB_APPLICANTS_SHORTLISTED_FAILURE,
      payload: {},
    });
    console.log('getJobApplicantShortlistedRequest : error : ', error);
  }
}

function* getJobApplicantRejectedRequest({ payload }) {
  try {
    const response = yield jobApplicantRequest.getJobApplicants_Rejected(payload);
    if (response) {
      yield put({
        type: action.GET_JOB_APPLICANTS_REJECTED_SUCCESS,
        payload: response.data.applicants,
      });
    } else {
      yield put({
        type: action.GET_JOB_APPLICANTS_REJECTED_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: action.GET_JOB_APPLICANTS_REJECTED_FAILURE,
      payload: {},
    });
    console.log('getJobApplicantRejectedRequest : error : ', error);
  }
}

function* getJobSeekerRequest({ payload }) {
  try {
    const response = yield jobApplicantRequest.getJobSeekers(payload);
    if (response) {
      yield put({
        type: action.GET_JOB_SEEKERS_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: action.GET_JOB_SEEKERS_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log(error.response)
    console.log('getJobSeekerRequest : error : ', error);
    yield put({
      type: action.GET_JOB_SEEKERS_FAILURE,
      payload: {},
    });
  }
}

function* getJobSeekers_JobFair({ id, payload }) {
  try {
    const response = yield jobFairRequest.getJobSeekers(id, payload);
    if (response) {
      yield put({
        type: action.GET_JOB_SEEKERS_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: action.GET_JOB_SEEKERS_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log(error.response)
    console.log('getJobSeekerRequest : error : ', error);
    yield put({
      type: action.GET_JOB_SEEKERS_FAILURE,
      payload: {},
    });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(action.GET_JOB_APPLICANTS_REQUEST, getJobApplicantRequest)]);
  yield all([takeEvery(action.GET_JOB_APPLICANTS_ACTIVE_REQUEST, getJobApplicantActiveRequest)]);
  yield all([takeEvery(action.GET_JOB_APPLICANTS_SHORTLISTED_REQUEST, getJobApplicantShortlistedRequest)]);
  yield all([takeEvery(action.GET_JOB_APPLICANTS_REJECTED_REQUEST, getJobApplicantRejectedRequest)]);
  yield all([takeEvery(action.GET_JOB_SEEKERS_REQUEST, getJobSeekerRequest)]);
  yield all([takeEvery(action.GET_JOB_SEEKERS_JOBFAIR_REQUEST, getJobSeekers_JobFair)]);
}
