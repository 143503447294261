import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export function SkeletonLoading({ variant, className, count, ...props }) {
    return <Skeleton count={count} {...props} className={className} />
}

function Loading({ show, color }) {
    if (!show) return null
    let variant = 'secondary'
    if (color) variant = color
    const classname = `spinner-grow spinner-grow-sm mx-1 text-${variant}`
    return (
        <div className="w-100 h-100 d-flex">
            <div className="d-flex m-auto">
                <div className={classname} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className={classname} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className={classname} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    )
}
export default Loading
