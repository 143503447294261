import moment from 'moment'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { jobFairRequest } from '../../service/requests'
import './styles.css'
function Events(props) {
    const { JobFairs } = props
    const { jobfairs } = JobFairs
    const [ongoingEvents, setOngoingEvents] = useState([])
    const [upcomingEvents, setUpcomingEvents] = useState([])
    const navigate = useNavigate()
    const eventLogoPlaceholder =
        'https://joblubucket.s3.ap-southeast-1.amazonaws.com/resources/defaultfairbadge.png'
    useEffect(() => {
        try {
            jobFairRequest.getUpcomingJobFairs().then((res) => {
                setUpcomingEvents(res?.data?.data)
            })
            jobFairRequest.getOngoingJobFairs().then((res) => {
                console.log('res', res?.data?.data)
                setOngoingEvents(res?.data?.data)
            })
        } catch (err) {
            console.log(err)
        }
    }, [])
    return (
        <div className="">
            <div className="bg-dark">
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 className="text-white fs20 mb-5">
                                Ongoing Events
                            </h5>
                        </div>
                        {!!ongoingEvents &&
                            ongoingEvents.map((job_fair) => {
                                return (
                                    <div className="col-md-6 mb-5 d-flex">
                                        <div className="me-4">
                                            <img
                                                src={
                                                    job_fair?.logo ||
                                                    eventLogoPlaceholder
                                                }
                                                width="120"
                                                alt=""
                                            />
                                        </div>
                                        <div className="w-100 position-relative">
                                            <h6 className="text-white">
                                                {job_fair?.name}
                                            </h6>
                                            <p className="text-white">
                                                In partnership with{' '}
                                                {job_fair?.partnerName}
                                            </p>
                                            <div className="w-100">
                                                <div className="d-flex flex-md-row flex-column justify-content-between">
                                                    <p className="text-white align-self-start align-md-self-start">
                                                        Event Period:
                                                        {moment(
                                                            job_fair?.startDate
                                                        ).format(
                                                            ' MMM. DD, YYYY'
                                                        )}
                                                        {' - '}
                                                        {moment(
                                                            job_fair?.endDate
                                                        ).format(
                                                            ' MMM. DD, YYYY'
                                                        )}
                                                    </p>
                                                    <button
                                                        className="mt-1 btn btn-job-fair"
                                                        onClick={() =>
                                                            navigate(
                                                                `/job-fair/register/${job_fair._id}`
                                                            )
                                                        }
                                                    >
                                                        Enter Fair
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
            <div className="bg-light">
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 className=" fs20 mb-5">Upcoming Events</h5>
                        </div>
                        {upcomingEvents.map((job_fair) => {
                            const isDisabled = !moment().isBetween(
                                moment(job_fair?.registrationDate),
                                moment(job_fair?.endDate)
                            )
                            return (
                                <div className="col-md-6 mb-5 d-flex">
                                    <div className="me-4">
                                        <img
                                            src={
                                                job_fair?.logo ||
                                                eventLogoPlaceholder
                                            }
                                            width="120"
                                            alt=""
                                        />
                                    </div>
                                    <div className="w-100 position-relative">
                                        <h6 className="">{job_fair?.name}</h6>
                                        <p className="">
                                            In partnership with{' '}
                                            {job_fair?.partnerName}
                                        </p>
                                        <div className="w-100">
                                            <div className="d-flex flex-md-row flex-column justify-content-between">
                                                <p className="align-self-start">
                                                    Event Period:{' '}
                                                    {moment(
                                                        job_fair?.startDate
                                                    ).format(
                                                        ' MMM. DD, YYYY'
                                                    )}{' '}
                                                    -
                                                    {moment(
                                                        job_fair?.endDate
                                                    ).format(' MMM. DD, YYYY')}
                                                </p>
                                                <button
                                                    className={`mt-1 btn ${
                                                        isDisabled
                                                            ? 'btn-primary'
                                                            : 'btn-outline-primary'
                                                    }`}
                                                    onClick={() =>
                                                        navigate(
                                                            `/job-fair/register/${job_fair._id}`
                                                        )
                                                    }
                                                    disabled={isDisabled}
                                                >
                                                    {'Register'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        JobFairs: state.JobFairs,
    }
}
export default connect(mapStateToProps, null)(Events)
