/* * * * * * * * * * * * * * * *
 *  ACTION TYPE naming convention
 *  VERB + '_REQUEST'
 *  VERB + '_SUCCESS'
 *  VERB + '_FAILURE'
 *
 *  ACTION naming convention
 *  verb : type
 * * * * * * * * * * * * * * * */
const action = {
  GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',

  SOFT_RESET: 'SOFT_RESET',

  softReset: () => ({
    type: action.SOFT_RESET
  }),

  getNotifications: (data) => ({
    type: action.GET_NOTIFICATIONS_REQUEST,
    payload: data
  }),
};

export default action;
