/* * * * * * * * * * * * * * * *
 *  ACTION TYPE naming convention
 *  VERB + '_REQUEST'
 *  VERB + '_SUCCESS'
 *  VERB + '_FAILURE'
 *
 *  ACTION naming convention
 *  verb : type
 * * * * * * * * * * * * * * * */
const action = {
  GET_JOB_MATCHES_REQUEST: 'GET_JOB_MATCHES_REQUEST',
  GET_JOB_MATCHES_SUCCESS: 'GET_JOB_MATCHES_SUCCESS',
  GET_JOB_MATCHES_FAILURE: 'GET_JOB_MATCHES_FAILURE',

  GET_MY_LIKED_CANDIDATES_REQUEST: 'GET_MY_LIKED_CANDIDATES_REQUEST',
  GET_MY_LIKED_CANDIDATES_SUCCESS: 'GET_MY_LIKED_CANDIDATES_SUCCESS',
  GET_MY_LIKED_CANDIDATES_FAILURE: 'GET_MY_LIKED_CANDIDATES_FAILURE',

  GET_LIKED_MY_JOBS_REQUEST: 'GET_LIKED_MY_JOBS_REQUEST',
  GET_LIKED_MY_JOBS_SUCCESS: 'GET_LIKED_MY_JOBS_SUCCESS',
  GET_LIKED_MY_JOBS_FAILURE: 'GET_LIKED_MY_JOBS_FAILURE',


  SOFT_RESET: 'SOFT_RESET',

  softReset: () => ({
    type: action.SOFT_RESET
  }),

  getJobMatches: (payload) => ({
    type: action.GET_JOB_MATCHES_REQUEST,
    payload: payload,
  }),

  getMyLikedCandidates: (payload) => ({
    type: action.GET_MY_LIKED_CANDIDATES_REQUEST,
    payload: payload
  }),

  getLikedMyJobs: (payload) => ({
    type: action.GET_LIKED_MY_JOBS_REQUEST,
    payload: payload
  }),
};

export default action;
