import defaultImg from '../../../../../assets/img/defaultImg.png'
import skillsIcon from '../../../../../assets/img/viewProfile/skillsIcon.png'
import softSkillsIcon from '../../../../../assets/img/viewProfile/softSkillsIcon.png'
import whatIValueIcon from '../../../../../assets/img/viewProfile/whatIValueIcon.png'
import employmentStatusIcon from '../../../../../assets/img/viewProfile/employmentStatusIcon.png'
import educationIcon from '../../../../../assets/img/viewProfile/educationIcon.png'
import experienceIcon from '../../../../../assets/img/viewProfile/experienceIcon.png'
import resumeIcon from '../../../../../assets/img/resumeIcon.svg'
import {
    get_url_extension,
    handle_file_download,
} from '../../../../../utils/helpers'
import ElevatorPitchV2 from '../../../../../components/reusables/elevator-pitch/ElevatorPitchV2'
import moment from 'moment'
export const SlidingProfile = (props) => {
    const { jobseeker, setIsViewProfile, setJobseeker } = props
    return (
        <>
            <div
                style={{
                    backgroundColor: '#00000066',
                    position: 'fixed',
                    zIndex: 9999,
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                }}
                onClick={()=>{
                    
                    setIsViewProfile(false)
                    setJobseeker(null)
                }}
            >
                <div
                    className="bg-white vh-100 w-100 shadow"
                    style={{
                        left: '30%',
                        right: 0,
                        top: 0,
                        bottom: 0,
                        position: 'fixed',
                        zIndex: 9999,
                        overflow: 'scroll',
                    }}
                >
                    <div
                        className="row"
                        style={{
                            position: 'relative',
                            width: '70%',
                        }}
                    >
                        <div className="col-md-12 px-5">
                            <div className="">
                                <div className="d-flex justify-content-between my-4 align-items-center">
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-arrow-left me-4 hand"
                                            viewBox="0 0 16 16"
                                            onClick={() => {
                                                setIsViewProfile(false)
                                                setJobseeker(null)
                                            }}
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                                            />
                                        </svg>
                                        <strong className="d-none">
                                            1 of 100 candidates
                                        </strong>
                                    </div>
                                    <div className="border rounded-pill px-4 py-1 d-none">
                                        <p>
                                            You have <strong>10 Likes</strong>{' '}
                                            left. <a href="">Learn more.</a>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-3">
                                            <div className="card-body d-flex justify-content-between">
                                                <div className="d-flex">
                                                    <div className="me-4">
                                                        <img
                                                            src={
                                                                jobseeker?.profileUrl
                                                                    ? jobseeker?.profileUrl
                                                                    : defaultImg
                                                            }
                                                            style={{
                                                                borderRadius: 3,
                                                                width: 150,
                                                                height: 150,
                                                                objectFit:
                                                                    'cover',
                                                            }}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-between">
                                                        <div>
                                                            <h5 className="text-primary text-capitalize">
                                                                <strong>
                                                                    {
                                                                        jobseeker?.firstName
                                                                    }{' '}
                                                                    {
                                                                        jobseeker?.lastName
                                                                    }
                                                                </strong>
                                                            </h5>
                                                            <p>
                                                                {
                                                                    jobseeker?.jobFunction
                                                                }
                                                            </p>
                                                            <p>
                                                                {
                                                                    jobseeker?.currentLocation
                                                                }
                                                            </p>
                                                            <div className="mb-2 mt-3">
                                                                <ElevatorPitchV2
                                                                    jobseeker={
                                                                        jobseeker
                                                                    }
                                                                    withSwipeAction={
                                                                        true
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="d-none">
                                                            <p>
                                                                This candidate
                                                                liked your job
                                                                post.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* HIDDEN */}
                                                <div className="d-none">
                                                    <div className="border border-warning rounded-pill px-4 py-1">
                                                        <p className="text-warning">
                                                            Premium Candidate
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* HIDDEN */}
                                        {jobseeker?.experienceHistory?.length >
                                            0 && (
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="me-4">
                                                            <img
                                                                src={
                                                                    experienceIcon
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div>
                                                            <h5 className="mb-3">
                                                                <strong>
                                                                    EXPERIENCE
                                                                </strong>
                                                            </h5>
                                                            {jobseeker?.experienceHistory.map(
                                                                (exp) => {
                                                                    return (
                                                                        <>
                                                                            <div className="mb-4">
                                                                                <p>
                                                                                    <strong>
                                                                                        {
                                                                                            exp.companyName
                                                                                        }
                                                                                    </strong>
                                                                                </p>
                                                                                <p>
                                                                                    {
                                                                                        exp.jobTitle
                                                                                    }
                                                                                </p>
                                                                                <p>
                                                                                    {
                                                                                        exp.location
                                                                                    }
                                                                                </p>
                                                                                <p>
                                                                                    {moment(
                                                                                        exp.startDate
                                                                                    ).format(
                                                                                        'MMM YYYY'
                                                                                    )}{' '}
                                                                                    -{' '}
                                                                                    {exp.expWorkingHere
                                                                                        ? 'Present'
                                                                                        : moment(
                                                                                              exp.endDate
                                                                                          ).format(
                                                                                              'MMM YYYY'
                                                                                          )}
                                                                                </p>
                                                                                <p className="mt-2">
                                                                                    <strong>
                                                                                        Responsibilities
                                                                                    </strong>
                                                                                </p>
                                                                                <p className="w-75">
                                                                                    {
                                                                                        exp.responsibilities
                                                                                    }
                                                                                </p>
                                                                                {exp.achievements && (
                                                                                    <>
                                                                                        <p className="mt-3">
                                                                                            <strong>
                                                                                                Awards
                                                                                            </strong>
                                                                                        </p>
                                                                                        <p className="w-75">
                                                                                            {
                                                                                                exp.achievements
                                                                                            }
                                                                                        </p>
                                                                                    </>
                                                                                )}
                                                                                {/* <ul>
                                                    <li>List 1</li>
                                                    <li>List 1</li>
                                                </ul> */}
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                            )}

                                                            <div className="d-none">
                                                                <a href="#">
                                                                    <strong>
                                                                        See all
                                                                        6
                                                                        experience
                                                                    </strong>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {/* HIDDEN */}
                                        {jobseeker?.educationHistory?.length >
                                            0 && (
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="d-flex mt-3">
                                                        <div className="me-4">
                                                            <img
                                                                src={
                                                                    educationIcon
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <h5 className="mb-3">
                                                                    <strong>
                                                                        Education
                                                                    </strong>
                                                                </h5>
                                                                {jobseeker?.educationHistory.map(
                                                                    (educ) => {
                                                                        return (
                                                                            <>
                                                                                <div className="mb-3">
                                                                                    <p>
                                                                                        <strong>
                                                                                            {
                                                                                                educ?.educField
                                                                                            }
                                                                                        </strong>
                                                                                    </p>
                                                                                    <p>
                                                                                        {
                                                                                            educ?.educationType
                                                                                        }
                                                                                    </p>
                                                                                    <p>
                                                                                        {
                                                                                            educ.educName
                                                                                        }
                                                                                    </p>
                                                                                    <p>
                                                                                        {moment(
                                                                                            educ.educStartDate
                                                                                        ).format(
                                                                                            'MMM YYYY'
                                                                                        )}{' '}
                                                                                        -{' '}
                                                                                        {moment(
                                                                                            educ.educEndDate
                                                                                        ).format(
                                                                                            'MMM YYYY'
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                )}
                                                            </div>
                                                            <div className="d-none">
                                                                <h5 className="mb-3">
                                                                    <strong>
                                                                        Certifications
                                                                        & Awards
                                                                    </strong>
                                                                </h5>
                                                                <div className="d-flex">
                                                                    {/* {jobseeker?.portfolioAndFiles?.map(
                                                            // (file) => {
                                                            //     let filename = `${
                                                            //         jobseeker?.firstName
                                                            //     } ${
                                                            //         jobseeker?.lastName
                                                            //     }.${get_url_extension(
                                                            //         file
                                                            //     )}`
                                                            //     filename =
                                                            //         filename
                                                            //             .split(
                                                            //                 ' '
                                                            //             )
                                                            //             .join(
                                                            //                 '_'
                                                            //             )

                                                            //     return (
                                                            //         <div
                                                            //             onClick={() =>
                                                            //                 handle_file_download(
                                                            //                     file,
                                                            //                     filename
                                                            //                 )
                                                            //             }
                                                            //         >
                                                            //             <img
                                                            //                 src={
                                                            //                     resumeIcon
                                                            //                 }
                                                            //                 alt=""
                                                            //                 className="cpointer mt-2 me-2"
                                                            //                 style={{
                                                            //                     width: 30,
                                                            //                 }}
                                                            //             />
                                                            //         </div>
                                                            //     )
                                                            // }
                                                        )} */}
                                                                </div>
                                                                {/* <div className="mb-3">
                                                    <p>
                                                        <strong>
                                                            BS Information
                                                            Technology - MIS
                                                        </strong>
                                                    </p>
                                                    <p>Bachelor's Degree</p>
                                                    <p>
                                                        Gordon College -
                                                        Olongapo City
                                                    </p>
                                                    <p>June 2015 - May 2019</p>
                                                </div>
                                                <div className="mb-3">
                                                    <p>
                                                        <strong>
                                                            BS Information
                                                            Technology - MIS
                                                        </strong>
                                                    </p>
                                                    <p>Bachelor's Degree</p>
                                                    <p>
                                                        Gordon College -
                                                        Olongapo City
                                                    </p>
                                                    <p>June 2015 - May 2019</p>
                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(jobseeker?.softSkills?.length > 0 ||
                                            jobseeker?.skills?.length > 0) && (
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="me-4">
                                                            <img
                                                                src={skillsIcon}
                                                                alt=""
                                                            />
                                                        </div>
                                                        {jobseeker?.skills
                                                            .length > 0 && (
                                                            <div>
                                                                <h5 className="mb-3">
                                                                    <strong>
                                                                        Technical
                                                                        Skills
                                                                    </strong>
                                                                </h5>
                                                                <div className="d-flex flex-wrap">
                                                                    {jobseeker?.skills?.map(
                                                                        (
                                                                            skill
                                                                        ) => {
                                                                            return (
                                                                                <p className="skills-pill me-2 mb-2">
                                                                                    {
                                                                                        skill
                                                                                    }
                                                                                </p>
                                                                            )
                                                                        }
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {jobseeker?.softSkills
                                                        .length > 0 && (
                                                        <div className="d-flex mt-3">
                                                            <div className="me-4">
                                                                <img
                                                                    src={
                                                                        softSkillsIcon
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div>
                                                                <h5 className="mb-3">
                                                                    <strong>
                                                                        Soft
                                                                        Skills
                                                                    </strong>
                                                                </h5>
                                                                <div className="d-flex flex-wrap">
                                                                    {jobseeker?.softSkills?.map(
                                                                        (
                                                                            softSkill
                                                                        ) => {
                                                                            return (
                                                                                <p className="skills-pill me-2 mb-2">
                                                                                    {
                                                                                        softSkill
                                                                                    }
                                                                                </p>
                                                                            )
                                                                        }
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {jobseeker?.importantForYou.length >
                                            0 && (
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="d-flex mt-3">
                                                        <div className="me-4">
                                                            <img
                                                                src={
                                                                    whatIValueIcon
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div>
                                                            <h5 className="mb-3">
                                                                <strong>
                                                                    What I Value
                                                                    Most
                                                                </strong>
                                                            </h5>
                                                            <div className="d-flex flex-wrap">
                                                                {jobseeker?.importantForYou?.map(
                                                                    (value) => {
                                                                        return (
                                                                            <p className="skills-pill me-2 mb-2">
                                                                                {
                                                                                    value
                                                                                }
                                                                            </p>
                                                                        )
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {/* HIDDEN */}
                                        {(jobseeker?.employmentStatus?.length >
                                            0 ||
                                            // jobseeker?.employmentType?.length > 0 ||
                                            jobseeker?.workSetup.length >
                                                0) && (
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="d-flex mt-3">
                                                        <div className="me-4">
                                                            <img
                                                                src={
                                                                    employmentStatusIcon
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div>
                                                            {jobseeker?.employmentStatus && (
                                                                <div className="mb-3">
                                                                    <h5 className="mb-3">
                                                                        <strong>
                                                                            Employment
                                                                            Status
                                                                        </strong>
                                                                    </h5>
                                                                    <div className="d-flex flex-wrap">
                                                                        <p className="skills-pill me-2 mb-2">
                                                                            {
                                                                                jobseeker?.employmentStatus
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {jobseeker
                                                                ?.employmentType
                                                                ?.length >
                                                                0 && (
                                                                <div className="mb-3">
                                                                    <h5 className="mb-3">
                                                                        <strong>
                                                                            Employment
                                                                            Type
                                                                        </strong>
                                                                    </h5>
                                                                    <div className="d-flex flex-wrap">
                                                                        {jobseeker?.employmentType?.map(
                                                                            (
                                                                                value
                                                                            ) => {
                                                                                return (
                                                                                    <p className="skills-pill me-2 mb-2">
                                                                                        {
                                                                                            value
                                                                                        }
                                                                                    </p>
                                                                                )
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {jobseeker
                                                                ?.workSetup
                                                                .length > 0 && (
                                                                <div className="mb-3">
                                                                    <h5 className="mb-3">
                                                                        <strong>
                                                                            Work
                                                                            Setup
                                                                        </strong>
                                                                    </h5>
                                                                    <div className="d-flex flex-wrap">
                                                                        {jobseeker?.workSetup?.map(
                                                                            (
                                                                                value
                                                                            ) => {
                                                                                return (
                                                                                    <p className="skills-pill me-2 mb-2">
                                                                                        {
                                                                                            value
                                                                                        }
                                                                                    </p>
                                                                                )
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
