import React from "react";

function Settings() {
  return (
    <div className="">
      <div className="card mb-3">
        <div className="card-header bg-white">
          <h6 className="">Language</h6>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 mb-3 col-sm-12">
              <select name="" id="" className="form-control">
                <option value="">English</option>
              </select>
            </div>
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary mt-2 px-4"
                id="savechanges"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-header bg-white">
          <h6 className="">Password Reset</h6>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12 col-sm-12 mb-2">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <h6 className="">Password Reset</h6>
                  <div className="form-group">
                    <label htmlFor="account-old-password fs14">Old Password</label>
                    <div className="input-group form-password-toggle input-group-merge">
                      <input
                        type="password"
                        className="form-control"
                        id="account-old-password"
                        name="password"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="account-new-password fs14">New Password</label>
                    <div className="input-group form-password-toggle input-group-merge">
                      <input
                        type="password"
                        id="account-new-password"
                        name="new-password"
                        className="form-control"
                      />
                    </div>
                    <p id="divCheckPasswordMatch"></p>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="account-retype-new-password fs14">
                      Retype New Password
                    </label>
                    <div className="input-group form-password-toggle input-group-merge">
                      <input
                        type="password"
                        className="form-control"
                        id="account-retype-new-password"
                        name="confirm-new-password"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary mt-2 px-4"
                    id="savechanges"
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-header bg-white">
          <h6 className="">Update Email Address</h6>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="">Current Email</label>
              <input
                type="text"
                className="form-control border-0 bg-white px-0"
                disabled
                value="janedoe@gmail.com"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="">New</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter New Email Address"
                />
              </div>
            </div>
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary mt-2 px-4"
                id="savechanges"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Settings;
