import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const CustomTooltip = ({ title, placement, ...props }) => {
    return (
        <OverlayTrigger
            placement={placement || 'top'}
            overlay={
                <Tooltip id="button-tooltip" {...props}>
                    {title}
                </Tooltip>
            }
            delay={{ show: 250, hide: 400 }}
        >
            {props.children}
        </OverlayTrigger>
    )
}
export default CustomTooltip
