// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCq_xSQyP9DnBr5T3ss9qNTeW_e4D3ymbQ",
  authDomain: "joblu-messages-stage.firebaseapp.com",
  projectId: "joblu-messages-stage",
  storageBucket: "joblu-messages-stage.appspot.com",
  messagingSenderId: "779889662046",
  appId: "1:779889662046:web:987e3a97c633fc34d07ccf",
  measurementId: "G-HCM7EH0C60"
};


// const firebaseConfig = {
//   apiKey: "AIzaSyC9SyO-c-8nfgrKO6fWmgi6jMeJ_Ve5Kv8",
//   authDomain: "wp-joblu-db-staging.firebaseapp.com",
//   projectId: "wp-joblu-db-staging",
//   storageBucket: "wp-joblu-db-staging.appspot.com",
//   messagingSenderId: "447024674312",
//   appId: "1:447024674312:web:a0a950d5195e2c616d43bd"
// };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
// export const db = firebase.firestore()
// export const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null
// export const isSupported = 'Notification' in window && messaging

export const db = firebase.firestore()
export const messaging = null
export const isSupported = false