import { all, put, takeEvery } from 'redux-saga/effects';
import { userRequest } from '../../service/requests';
import action from './action';

function* getNotificationsRequest({payload}) {
  try {
    const response = yield userRequest.getNotifications(payload);
    if (response) {
      yield put({
        type: action.GET_NOTIFICATIONS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: action.GET_NOTIFICATIONS_FAILURE,
        payload: {},
      });
    }
  } catch (error) {
    console.log('getNotificationsRequest : error : ', error);
    console.log(error.response.data)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(action.GET_NOTIFICATIONS_REQUEST, getNotificationsRequest)]);
}
