import { useEffect, useState } from 'react'
import videoPlayback from '../../../assets/img/videoPlayback.svg'
import { profilePlaceholder_Applicant } from '../../../utils/constants'
import JobluVideoPlayer from '../../../views/explore/components/VideoPlayer'
import reelIcon from '../../../assets/img/viewProfile/reelIcon.png'
const ElevatorPitchV2 = (props) => {
    const {
        isProfilePicture,
        jobseeker,
        width,
        height,
        hasSwipeActions,
        hasSubs,
        isLikedMe,
        job,
    } = props
    const [playVideo, setPlayVideo] = useState(false)
    const [showPitch, setShowPitch] = useState(true)
    const handlePlayVideo = () => {
        setPlayVideo(!playVideo)
    }

    const img_src =
        jobseeker?.anonymous && !hasSwipeActions
            ? profilePlaceholder_Applicant
            : jobseeker?.profileUrl || profilePlaceholder_Applicant
    const canva_id = `canvas-${jobseeker?._id}-${job?._id}}`
    const canva = document.getElementById(canva_id)
    const img = new Image()
    useEffect(() => {
        if (!hasSubs) {
            if (canva) {
                img.src = img_src
                // canva.width = '100%'
                // canva.height = '100%'
                // const blur = new Blur(canva, img)
                const ctx = canva.getContext('2d')
                canva.style.width = '100%'
                canva.style.height = '100%'
                img.onload = () => {
                    ctx.drawImage(img, 0, 0, canva.width, canva.height)
                    ctx.filter = 'blur(10px)'
                }
            }
        }
    }, [canva, img])

    useEffect(() => {
        if (isLikedMe && !hasSubs) setShowPitch(false)
    }, [isLikedMe, hasSubs])

    useEffect(() => {
        if (document.getElementsByClassName('prc2')) {
            var items = document.getElementsByClassName('prc2')
            for (var i = 0; i < items.length; i++) {
                items[i].addEventListener(
                    'contextmenu',
                    function (e) {
                        e.preventDefault()
                    },
                    false
                )
            }
            document.getElementsByClassName('prc2').onselectstart =
                disableselect
        }
    }, [])

    const disableselect = (f) => {
        return false
    }

    return (
        <div className="">
            {jobseeker?.elevatorPitch && showPitch && (
                <>
                    <span
                        className="text-white text-center bg-primary rounded-pill px-3 p-1 hand"
                        style={{
                            boxShadow: '0px 0px 10px #00AAFF',
                            fontSize: 14,
                        }}
                        onClick={handlePlayVideo}
                    >
                        <img src={reelIcon} alt="" style={{marginBottom: 4, marginRight: 10}}/>
                        JobReel
                    </span>
                    {/* <img
                        src={videoPlayback}
                        alt=""
                        className="m-auto  position-absolute"
                        style={{
                            borderRadius: '50%',
                            width: 35,
                            height: 35,
                            right: 2,
                            bottom: 2,
                            opacity: 30,
                        }}
                        role="button"
                        onClick={handlePlayVideo}
                    /> */}
                    <JobluVideoPlayer
                        src={jobseeker?.elevatorPitch}
                        play={playVideo}
                        setPlayVideo={setPlayVideo}
                    />
                </>
            )}
        </div>
    )
}

export default ElevatorPitchV2
