import { useNavigate } from 'react-router-dom'
import checkIcon from '../../assets/img//pricing-page/check.svg'
import { pricing_list } from './prcing-list'
import { SubscriptionButtons } from './Pricing'
const SubscriptionCancelled = () => {
    const navigate = useNavigate()
    return (
        <div>
            <button
                className="btn text-primary ms-3 ms-md-5 mt-4"
                onClick={() => {
                    navigate('/profile')
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                >
                    <path
                        fill-rule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                </svg>{' '}
                Joblu Premium
            </button>

            <div
                className="row justify-content-center w-100"
                style={{ height: '80vh' }}
            >
                <div className="col-12 col-md-5">
                    {/* <img src={nothing} alt="" style={{ width: 250 }} /> */}
                    <h5 className=" mt-5">
                        Joblu Premium Subscription has been cancelled.
                    </h5>
                    <p className="">
                        For the mean time you can still use Joblu with limited
                        features. Just in case you change your mind please go
                        and review our premium plans again.
                        <br /> <br />
                        Here are premium features that you’re missing:
                    </p>
                    <div
                        className="rounded p-4 mt-3 shadow-sm"
                        style={{ borderRadius: 20 }}
                    >
                        <h5 className="text-primary poppins">
                            Connect to more candidates
                        </h5>
                        <p className="poppins">
                            Save thousand in recruitment cost and source the
                            right candidate the fast, fun, and fabulous way!
                        </p>
                        {pricing_list.map((feat) => {
                            return (
                                <div
                                    className={`row mx-0 feature-container p-2 mt-2 ${
                                        feat?.border_gold && 'border-gold'
                                    }`}
                                >
                                    <div className="col-9">
                                        <span
                                            style={{
                                                fontSize: 18,
                                                marginRight: 5,
                                            }}
                                        >
                                            {feat.icon}
                                        </span>
                                        {feat.label}
                                    </div>

                                    <div className="col-3 text-center">
                                        <img src={checkIcon} alt="" />
                                    </div>
                                </div>
                            )
                        })}
                        <hr />
                        <SubscriptionButtons />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionCancelled
