import { useEffect, useState } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
function CustomAlert({ body, show, position, autoHide, delay, variant }) {
    const [delayMs, setDelayMs] = useState(2000)
    const [showAlert, setShowAlert] = useState(show)
    useEffect(() => {
        setShowAlert(show)
    }, [show])

    useEffect(() => {
        if (delay) setDelayMs(delay)
    }, [delay])

    useEffect(() => {
        if (autoHide) {
            setTimeout(() => {
                setShowAlert(false)
            }, delayMs)
        }
    }, [autoHide])

    if (!showAlert) return null

    return (
        <ToastContainer
            position={position}
            style={{
                position: 'fixed',
                zIndex: 99999,
                right: 18,
                top: 18,
                borderRadius: 5,
            }}
        >
            <Toast bg={variant || 'primary'}>
                <Toast.Body className="text-white">{body}</Toast.Body>
            </Toast>
        </ToastContainer>
    )
}

export default CustomAlert
