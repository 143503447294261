import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import jobfairActions from '../../redux/job-fair/jobfairActions'
import Explore from '../explore/Explore'

function JobFairExplore({ ...props }) {
    const { jobfair_id } = useParams()
    const [jobfairData, setjobFairData] = useState(null)
    const location = useLocation()
    useEffect(() => {
        if (location?.state?.jobfair) {
            setjobFairData(location?.state?.jobfair)
        }
    }, [location])

    return <Explore isFromJobFair={jobfairData} />
}
const mapStateToProps = (state) => {
    return {
        JobFairs: state.JobFairs,
    }
}
const mapDispatchToProps = {
    setSignInToFair: jobfairActions.setSignIn,
}
export default connect(mapStateToProps)(JobFairExplore)
