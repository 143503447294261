import { TourButtons } from './Explore'
const opositeSide = {
    top: 'bottom',
    bottom: 'top',
    right: 'left',
    left: 'right',
}

const popoverPadding = 10

export const doArrow = (position, verticalAlign, horizontalAlign) => {
    if (!position || position === 'custom') {
        return {}
    }

    const width = 16
    const height = 12
    const color = 'white'
    const isVertical = position === 'top' || position === 'bottom'
    const spaceFromSide = popoverPadding
    const obj = {
        [isVertical ? 'borderLeft' : 'borderTop']: `${
            width / 2
        }px solid transparent`, // CSS Triangle width
        [isVertical ? 'borderRight' : 'borderBottom']: `${
            width / 2
        }px solid transparent`, // CSS Triangle width
        [`border${position[0].toUpperCase()}${position.substring(
            1
        )}`]: `${height}px solid ${color}`, // CSS Triangle height
        [isVertical ? opositeSide[horizontalAlign] : verticalAlign]:
            height + spaceFromSide, // space from side
        [opositeSide[position]]: -height + 2,
    }

    return {
        '&::after': {
            content: "''",
            width: 0,
            height: 0,
            position: 'absolute',
            ...obj,
        },
    }
}

const tourSteps = [
    {
        selector: "[data-tut='explore-step-1']",
        content: (
            <div className="">
                <h5 className="open-sans text-center fw-bold">
                    Explore Tutorial 1/8
                </h5>
                <p className="text-center">
                    Ready to hire? Click + to create a job post.
                </p>
                <TourButtons />
            </div>
        ),
    },
    {
        selector: '#explore-tour-step-2',
        content: (
            <div className="">
                <h5 className="open-sans text-center fw-bold">
                    Explore Tutorial 2/8
                </h5>
                <p className="text-center">
                    View candidates who match this job title.
                </p>
                <TourButtons />
            </div>
        ),
    },
    {
        selector: '.explore-tour-step-3',
        content: (
            <div className="">
                <h5 className="open-sans text-center fw-bold">
                    Explore Tutorial 3/8
                </h5>
                <p className="text-center">
                    Use custom filters to narrow your candidate search.
                </p>
                <TourButtons />
            </div>
        ),
    },
    {
        selector: '#jobseeker_card_tutorial',
        content: (
            <div className="">
                <h5 className="open-sans text-center fw-bold">
                    Explore Tutorial 4/8
                </h5>
                <p className="text-center">
                    View the candidate’s card here and scroll through.
                </p>
                <TourButtons />
            </div>
        ),
    },
    {
        selector: '#jobseeker_card_dots',
        content: (
            <div className="">
                <h5 className="open-sans text-center fw-bold">
                    Explore Tutorial 5/8
                </h5>
                <p className="text-center">
                    Click Expand to view a candidate’s full profile.
                </p>
                <TourButtons />
            </div>
        ),
    },

    {
        selector: '#explore-dismiss-action',
        content: (
            <div className="">
                <h5 className="open-sans text-center fw-bold">
                    Explore Tutorial 6/8
                </h5>
                <p className="text-center">
                    Click "Dismiss" to skip the candidate.
                </p>
                <TourButtons />
            </div>
        ),
    },
    {
        selector: '#explore-like-action',
        content: (
            <div className="">
                <h5 className="open-sans text-center fw-bold">
                    Explore Tutorial 7/8
                </h5>
                <p className="text-center">
                    Click "Like" to show that you're interested in the
                    candidate.
                </p>
                <TourButtons />
            </div>
        ),
    },
    {
        selector: '#explore-super-like-action',
        content: (
            <div className="">
                <h5 className="open-sans text-center fw-bold">
                    Explore Tutorial 8/8
                </h5>
                <p className="text-center">
                    Click "Super Like" to stand out from other employers. You
                    can use up to 3 super likes per month so choose wisely.
                </p>
                <TourButtons isLast />
            </div>
        ),
    },
]

export default tourSteps
