import React, { useEffect, useMemo, useState } from 'react'
import filterIcon from '../../../assets/img/filterIcon.svg'
import { SkeletonLoading } from '../../../components/reusables/Loading'
import SelectCountry2 from '../../../components/reusables/SelectCountry2'
import SelectJobFunction from '../../../components/reusables/SelectJobFunction'
import { configRequest } from '../../../service/requests'
import useWindowDimensions from '../../../utils/hooks'
import ExploreActions from './ExploreActions'
import JobSeekerCard from './JobSeekerCard'
import NoJobSeekersFound from './NoJobSeekersFound'
function ShrinkedExplore(props) {
    const {
        expanded,
        jobSeekers,
        setJobSeekers,
        setExpanded,
        onSwipeFulfilled,
        isLoading,
        setFilters,
        filters,
        handleFilter,
        freezeScreenOnSwipe,
        getJobSeekers,
    } = props

    let showingIndex = 0
    const countryCodes = require('country-codes-list')
    const myCountryCodesObject = countryCodes.customList(
        'countryCode',
        '{countryNameEn}:+{countryCallingCode}'
    )
    const [countryCodesList, setCountryCodesList] = useState(
        Object.values(myCountryCodesObject).map((item) => {
            var temp = item.split(':')
            return {
                label: `${temp[0]} (${temp[1]})`,
                value: item,
            }
        })
    )
    const childRefs = useMemo(
        () =>
            Array(jobSeekers.length)
                .fill(0)
                .map((i) => React.createRef()),
        [jobSeekers]
    )
    const swipe = async (dir) => {
        if (showingIndex < jobSeekers.length) {
            await childRefs[showingIndex]?.current.swipe(dir) // Swipe the card!
        }
    }

    const [jobFunctions, setJobfunctions] = useState([])

    const { width } = useWindowDimensions()
    useEffect(() => {
        try {
            configRequest.getJobFunctions().then((res) => {
                setJobfunctions(res?.data?.data)
            })
        } catch (err) {
            console.log(err)
        }
    }, [])
    return (
        <div
            className="col-12 col-md-9 row pt3 pt-md-5"
            style={{ minHeight: '68vh' }}
        >
            <div className="col-sm-3 p-0 px-1 d-none d-sm-block">
                <div className="d-flex justify-content-between">
                    <div>
                        <h5>Filter</h5>
                    </div>
                    <div>
                        <img src={filterIcon} alt="" className="hw-24" />
                    </div>
                </div>

                <hr />
                <div className={width > 640 && 'explore-tour-step-3'}>
                    <div className="mb-3 w-100">
                        <SelectJobFunction
                            onChange={(value) => {
                                setFilters({
                                    ...filters,
                                    jobFunction: value,
                                })
                            }}
                            value={filters?.jobFunction}
                        />
                    </div>

                    <div className=" mb-3 w-100">
                        <SelectCountry2
                            onChange={(value) => {
                                setFilters({
                                    ...filters,
                                    location: value,
                                })
                            }}
                            value={filters?.location}
                            noCellCode
                            grouped
                        />
                    </div>
                    <button
                        className="btn btn-primary px-4"
                        onClick={handleFilter}
                        style={{ marginLeft: 1 }}
                    >
                        Search
                    </button>
                </div>
            </div>
            <div className="col-12 col-sm-9">
                {!isLoading ? (
                    <>
                        <div
                            className="pb-5 position-relative"
                            style={{ minHeight: '50vh' }}
                        >
                            {!jobSeekers?.length && (
                                <NoJobSeekersFound
                                    getJobSeekers={handleFilter}
                                />
                            )}

                            {jobSeekers.map((jobseeker, index) => {
                                showingIndex = index
                                const isLast = jobSeekers.length - 1 === index
                                return (
                                    <div
                                        className="position-absolute w-100 p-0 p-md-3"
                                        id={`card-shrinked-${index}`}
                                        style={{
                                            background: '#fff',
                                            zIndex: 3,
                                            display:
                                                jobSeekers.length - 1 === index
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                    >
                                        <JobSeekerCard
                                            key={jobseeker?._id}
                                            jobseeker={jobseeker}
                                            setExpanded={setExpanded}
                                            jobseekerCardId={jobseeker?._id}
                                            isLast={isLast}
                                            onSwipeFulfilled={onSwipeFulfilled}
                                            index={index}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        <div className="w-100 mt-5 pt-2">
                            {!!jobSeekers?.length && (
                                <ExploreActions
                                    showingIndex={showingIndex}
                                    onSwipeFulfilled={onSwipeFulfilled}
                                    data={jobSeekers[showingIndex]}
                                    type="shrinked"
                                />
                            )}
                        </div>
                    </>
                ) : (
                    <div className="h-100">
                        <SkeletonLoading className="h-75" />
                    </div>
                )}
            </div>
        </div>
    )
}

export default ShrinkedExplore
