function CustomChip(props) {
    const { data, variant, onDelete } = props
    return (
        <div className="w-100 d-flex flex-wrap">
            {data?.map((skill, index) => (
                <span
                    key={`${skill}${index}`}
                    className={`${
                        variant === 'warning'
                            ? 'bg-warning pill rounded-pill fs-12 me-1'
                            : 'skills-pill me-2 mb-2'
                    } ${onDelete ? 'ps-3 pe-2' : 'px-3'} py-1`}
                >
                    {skill}
                    {onDelete && (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className=" ms-2 bi bi-x-circle"
                            viewBox="0 0 16 16"
                            role="button"
                            onClick={() => onDelete(index)}
                        >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    )}
                </span>
            ))}
            {!data?.length && (
                <p
                    className="px-3 py-1"
                    style={{ fontSize: 12, lineHeight: '21px' }}
                >
                    None
                </p>
            )}
        </div>
    )
}
export default CustomChip
