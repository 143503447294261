import { useEffect, useState } from 'react'

function WizardStep3(props) {
    const char_limit = 280
    const { profile, setProfile, errors } = props
    const [charLeft, setCharLeft] = useState(char_limit)

    useEffect(() => {
        if (profile?.companyOverview) {
            const left = char_limit - profile.companyOverview.length
            setCharLeft(left)
            if (profile.companyOverview.length > char_limit) {
                const trimmed = profile.companyOverview.substring(0, char_limit)
                setProfile({
                    ...profile,
                    companyOverview: trimmed,
                })
                setCharLeft(0)
            }
        }
    }, [profile.companyOverview])
    return (
        <div id="wizard-steps">
            <h2 className="">Company Profile</h2>
            <p className="mb-3">Tell us more about your company</p>
            <div className="mb-3">
                <textarea
                    className="form-control border"
                    id=""
                    rows="5"
                    value={profile?.companyOverview || ''}
                    onChange={(e) => {
                        if (charLeft >= 0) {
                            setProfile({
                                ...profile,
                                companyOverview: e.target.value,
                            })
                        }
                    }}
                ></textarea>
                <p className="text-end">
                    {' '}
                    <span>
                        Characters {profile?.companyOverview?.length}/280
                    </span>
                </p>
            </div>
        </div>
    )
}

export default WizardStep3
