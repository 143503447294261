import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CustomTooltip from '../../components/reusables/CustomTooltip'
import userAction from '../../redux/user/actions'
import { userRequest } from '../../service/requests'
import { makeUploadRequest } from '../../utils/uploadFiles'
import { validateURL } from '../../utils/validators'
import WizardStep1 from './components/WizardStep1'
import WizardStep2 from './components/WizardStep2'
import WizardStep3 from './components/WizardStep3'
import WizardStep4 from './components/WizardStep4'
import './styles.css'

function ProfileWizard(props) {
    const { User, getUser } = props
    const [steps, setSteps] = useState(1)
    const [logoFile, setLogoFile] = useState(null)
    const navigate = useNavigate()
    const [errors, setErrors] = useState({
        website: null,
    })
    const handleNext = (skip) => {
        let wizardSteps = document.getElementById('wizard-steps')
        console.log(skip)
        if (!skip) {
            if (!!profile?.companyWebsite) {
                const isValid = validateURL(profile.companyWebsite)
                if (!isValid) {
                    setErrors({
                        ...errors,
                        website: 'Not a valid URL',
                    })
                } else {
                    console.log('isValid', isValid)
                    // wizardSteps.classList.add('scale-out-left')
                    setSteps(steps + 1)
                    setErrors({
                        ...errors,
                        website: null,
                    })
                }
            } else {
                // wizardSteps.classList.add('scale-out-left')
                setSteps(steps + 1)
            }
        } else {
            setSteps(steps + 1)
        }
    }
    const handlePrev = () => {
        let wizardSteps = document.getElementById('wizard-steps')
        // wizardSteps.classList.add('scale-out-left')
        setSteps(steps - 1)
    }

    const [profile, setProfile] = useState(User?.user)

    useEffect(() => {
        console.log(profile)
    }, [profile])

    const handleUpdate = async () => {
        console.log('updating...')
        try {
            let profileUrl
            if (logoFile) {
                profileUrl = await makeUploadRequest(logoFile, 'profileimage')
            }
            let payload = {
                companyWebsite: profile.companyWebsite,
                companyOverview: profile.companyOverview,
                companySize: parseInt(profile.companySize),
                profileUrl,
            }
            if (!profileUrl) delete payload.profileUrl
            userRequest.updateEmployer(payload).then((res) => {
                if (res) {
                    getUser()
                    navigate('/profile')
                }
            })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        const listener = (event) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault()
                if (steps !== 4) {
                    handleNext()
                } else {
                    handleUpdate()
                }
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [handleNext, handleUpdate])

    return (
        <div className="row vh-100 w-100 mx-0">
            <div className="col-md-4 profile-wizard-bg p-0 h-100 d-flex align-items-center d-none d-md-flex "></div>
            <div className="col-md-8 position-relative p-0 d-flex flex-column align-items-center justify-content-center bg-white create-job-form">
                <div
                    style={{
                        position: 'absolute',
                        top: 20,
                        left: 30,
                    }}
                >
                    <div
                        role="button"
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        <CustomTooltip title="Go back" placement="left">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                fill="currentColor"
                                class="bi bi-arrow-left"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                                />
                            </svg>
                        </CustomTooltip>{' '}
                        Go back
                    </div>
                </div>
                <div className="container">
                    <div>
                        <p className="text-end">Step {steps} of 4</p>
                    </div>
                    {steps === 1 && (
                        <WizardStep1
                            profile={profile}
                            setProfile={setProfile}
                            logoFile={logoFile}
                            setLogoFile={setLogoFile}
                        />
                    )}
                    {steps === 2 && (
                        <WizardStep2
                            profile={profile}
                            setProfile={setProfile}
                            errors={errors}
                        />
                    )}
                    {steps === 3 && (
                        <WizardStep3
                            profile={profile}
                            setProfile={setProfile}
                        />
                    )}
                    {steps === 4 && (
                        <WizardStep4
                            profile={profile}
                            setProfile={setProfile}
                        />
                    )}
                    <div className="d-flex align-items-center justify-content-between w-100 mt-3">
                        {steps > 1 && (
                            <div>
                                <span className="hand" onClick={handlePrev}>
                                    Previous
                                </span>
                            </div>
                        )}
                        <div className="ms-auto d-flex">
                            {steps !== 4 ? (
                                <div className="d-flex">
                                    <button
                                        className="btn me-3"
                                        onClick={() => handleNext(true)}
                                    >
                                        Skip
                                    </button>
                                    <button
                                        className="btn btn-primary me-3"
                                        onClick={() => handleNext()}
                                    >
                                        Next
                                    </button>
                                </div>
                            ) : (
                                <button
                                    className="btn btn-primary me-3"
                                    onClick={handleUpdate}
                                >
                                    Finish
                                </button>
                            )}
                            <span>or press Enter</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return { User: state.User }
}
const mapDispatchToProps = {
    getUser: userAction.getUser,
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileWizard)
