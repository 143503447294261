import action from './action';

export const initState = {
  isFetching: false,
  isFetched: false,

  isFetchingLikedCandidates: false,
  isFetchedLikedCandidates: false,

  isFetchingLikedMyJobs: false,
  isFetchedLikedMyJobs: false,

  isFetchingMyLikedJobs: false,
  isFetchedMyLikedJobs: false,

  isFetchingLikedMe: false,
  isFetchedLikedMe: false,
  
  jobMatchesList: [],
  myLikedCandidates: {
    totalCount: 0,
    totalUnread: 0,
    page: 0,
    limit: 10,
    data: []
  },
  likedMyJobs: {
    totalCount: 0,
    totalUnread: 0,
    page: 0,
    limit: 10,
    data: []
  },
};

const jobMatchesReducer = (state = initState, act) => {
  switch (act.type) {
    case action.GET_JOB_MATCHES_REQUEST:
      console.log('JOB MATCHES REDUCER :: GET_JOB_MATCHES_REQUEST');
      return {
        ...state,
        isFetching: true,
      };
    case action.GET_JOB_MATCHES_SUCCESS:
      console.log('JOB MATCHES REDUCER :: GET_JOB_MATCHES_SUCCESS');
      return {
        ...state,
        isFetched: true,
        isFetching: false,
        jobMatchesList: act.payload,
      };
    case action.GET_JOB_MATCHES_FAILURE:
      console.log('JOB MATCHES REDUCER :: GET_JOB_MATCHES_FAILURE');
      return {
        ...state,
        isFetching: false,
        isFetched: false,
      };
    case action.GET_MY_LIKED_CANDIDATES_REQUEST:
      console.log('JOB MATCHES REDUCER :: GET_MY_LIKED_CANDIDATES_REQUEST');
      return {
        ...state,
        isFetchingLikedCandidates: true,
        isFetchedLikedCandidates: false
      };
    case action.GET_MY_LIKED_CANDIDATES_SUCCESS:
      console.log('JOB MATCHES REDUCER :: GET_MY_LIKED_CANDIDATES_SUCCESS');
      console.log(act.payload)
      if(act.payload.page > 1){
        const currentList = state?.myLikedCandidates?.data
        let newList = act.payload
        newList.data = [...currentList, ...newList.data]
        return {
          ...state,
          isFetchedLikedCandidates: true,
          isFetchingLikedCandidates: false,
          myLikedCandidates: newList,
        };
      }
      else{
        return {
          ...state,
          isFetchedLikedCandidates: true,
          isFetchingLikedCandidates: false,
          myLikedCandidates: act.payload,
        };
      }
    case action.GET_MY_LIKED_CANDIDATES_FAILURE:
      console.log('JOB MATCHES REDUCER :: GET_MY_LIKED_CANDIDATES_FAILURE');
      return {
        ...state,
        isFetchingLikedCandidates: false,
        isFetchedLikedCandidates: false,
      };
    case action.GET_LIKED_MY_JOBS_REQUEST:
      console.log('JOB MATCHES REDUCER :: GET_LIKED_MY_JOBS_REQUEST');
      return {
        ...state,
        isFetchingLikedMyJobs: true,
        isFetchedLikedMyJobs: false
      };
    case action.GET_LIKED_MY_JOBS_SUCCESS:
      console.log('JOB MATCHES REDUCER :: GET_LIKED_MY_JOBS_SUCCESS');
      if(act.payload.page > 1){
        const currentList = state?.likedMyJobs?.data
        let newList = act.payload
        newList.data = [...currentList, ...newList.data]
        return {
          ...state,
          isFetchedLikedMyJobs: true,
          isFetchingLikedMyJobs: false,
          likedMyJobs: newList,
        };
      }
      else{
        return {
          ...state,
          isFetchedLikedMyJobs: true,
          isFetchingLikedMyJobs: false,
          likedMyJobs: act.payload,
        };
      }
    case action.GET_LIKED_MY_JOBS_FAILURE:
      console.log('JOB MATCHES REDUCER :: GET_LIKED_MY_JOBS_FAILURE');
      return {
        ...state,
        isFetchingLikedMyJobs: false,
        isFetchedLikedMyJobs: false,
      };
    case action.SOFT_RESET:
      console.log('JOB MATCHES REDUCER :: SOFT_RESET');
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};


export default jobMatchesReducer;