import React from 'react'
import { Button, Modal } from 'react-bootstrap'

function PopUpModal(props) {
    const { showModal, setShowModal } = props
    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <div className="row p-5">
                <h4 className="text-center">{props.title}</h4>
                <div className="col-12 d-flex justify-content-center my-2">
                    <img style={{ width: 150 }} src={props.icon} alt="" />
                </div>
                <div className="col-12">{props.children}</div>
                {!!props.onButtonClick && (
                    <div className="col-12 d-flex justify-content-center mt-4">
                        <Button
                            className="px-5 py-2"
                            onClick={() => props.onButtonClick()}
                        >
                            {props.buttonText}
                        </Button>
                    </div>
                )}
            </div>
        </Modal>
    )
}
export default PopUpModal
