import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { profilePlaceholder_Applicant } from '../../../utils/constants'
import { fromNow } from '../../../utils/helpers'
function CustomToast({ data }) {
    const navigate = useNavigate()
    const [stacks, setStacks] = useState([])

    const handleClose = (index) => {
        console.log(index)
        let temp = stacks
        temp.splice(index, 1)
        setStacks([...temp])
    }

    useEffect(() => {
        setStacks(_.take(data, 3))
    }, [data])

    useEffect(() => {
        if (stacks.length > 0) {
            setTimeout(() => {
                setStacks([])
            }, 5000)
        }
    }, [stacks])

    const handleClick = (type, data) => {
        if (type === 'like') {
            setStacks([])
            navigate('/likes', {
                state: { tab: 'liked_me', data },
            })
        } else {
            console.log('no actions')
        }
    }

    return (
        <ToastContainer position="bottom-end" style={{ zIndex: 9000 }}>
            {stacks?.map((toast, index) => {
                const { notification } = toast
                const data = toast?.data?.data && JSON.parse(toast?.data?.data)
                const type = toast?.data?.type

                return (
                    <Toast
                        onClose={() => handleClose(index)}
                        // delay={3000}
                        // autohide
                        onClick={() => handleClick(type, data)}
                    >
                        <Toast.Header>
                            <img
                                src={
                                    data?.profileUrl ||
                                    profilePlaceholder_Applicant
                                }
                                className="rounded me-2"
                                alt=""
                                width="15"
                                height="15"
                            />
                            <strong className="me-auto">
                                New {notification?.title}
                            </strong>
                            <small className="text-muted">
                                {fromNow(data?.createdAt)}
                            </small>
                        </Toast.Header>
                        <Toast.Body>{notification?.body}</Toast.Body>
                    </Toast>
                )
            })}
        </ToastContainer>
    )
}

export default CustomToast
