function Select({
    options,
    error,
    onChange,
    defaultValue,
    value,
    country,
    className,
}) {
    return (
        <div className="mb-3">
            {!error ? (
                <select
                    className={`form-select mb-0 ${className}`}
                    onChange={onChange}
                    defaultValue={defaultValue}
                    value={value}
                >
                    {!!country && (
                        <option value="" disabled selected>
                            Select Country
                        </option>
                    )}
                    {!!country &&
                        options?.map((opt) => {
                            let label = country
                                ? opt?.value.split(':')[0]
                                : opt?.value
                            return (
                                <option key={opt?.value} value={opt?.value}>
                                    {label}
                                </option>
                            )
                        })}
                    {!country &&
                        options?.map((opt) => {
                            return (
                                <option key={opt?.value} value={opt?.value}>
                                    {opt.label}
                                </option>
                            )
                        })}
                </select>
            ) : (
                <div>
                    <select
                        onChange={onChange}
                        className="form-select mb-0 border border-danger text-danger"
                        defaultValue={defaultValue}
                        value={value}
                    >
                        <option>Select Country</option>
                        {options?.map((opt) => {
                            let label = country
                                ? opt?.value.split(':')[0]
                                : opt?.value
                            return (
                                <option key={opt?.value} value={opt?.value}>
                                    {label}
                                </option>
                            )
                        })}
                    </select>
                    <span className="error-helper mt-0">{error}</span>
                </div>
            )}
        </div>
    )
}

export default Select
