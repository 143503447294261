import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../../components/reusables/Loading'

const RegisterCode = () => {
    const params = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        if (params?.code) {
            navigate('/signup', {
                state: {
                    registercode: params.code,
                },
            })
        }
    }, [params])

    return (
        <div className="d-flex" style={{ height: '100vh' }}>
            <div className="m-auto">
                <Loading show />
            </div>
        </div>
    )
}
export default RegisterCode
