import axios from 'axios'
import rootAction from '../redux/action'
import { store } from '../redux/store'
import { baseURL, BEARER } from '../utils/constants'
const axiosInstance = axios.create({
    baseURL: baseURL,
})

axiosInstance.interceptors.request.use(
    (config) => {
        const token = store.getState().User.token
        if (token) {
            config.headers.Authorization = BEARER + token
        }
        // console.log('Axios Request :');
        return config
    },
    ({ message, response: { data, status } }) => {
        return Promise.reject({ message, data, status })
    }
)

axiosInstance.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // console.log('Axios Response ')
        return response
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // console.log('Axios response error:');

        if (error.response.status === 401) {
            const { dispatch, getState } = store
            const currentUser = store.getState().User

            const paths = window.location.pathname.split('/')
            console.log('', paths)
            if (paths[1] === 'settings') {
                console.log('closing')
                window.close()
            } else {
                setTimeout(() => {
                    dispatch(rootAction.signOut())
                }, 2000)
            }

            //return dispatch(userAction.renewAccessToken({ user: currentUser }));
            //dispatch(rootAction.signOut());
            // navigation.dispatch(
            //   CommonActions.reset({
            //     index: 0,
            //     routes: [{ name: 'Splash' }],
            //   }),
            // );
        }

        return Promise.reject(error)
    }
)

export default axiosInstance
