import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import ReactPlayer from 'react-player'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../../components/reusables/Loading'
import userActions from '../../redux/user/actions'
import { userRequest } from '../../service/requests'

import './style.css'

function Promote(props) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,

        acceptedFiles,
    } = useDropzone({
        accept: {
            'video/mp4': ['.mp4'],
        },
    })
    const params = useParams()
    const [video, setvideo] = useState(null)
    useEffect(() => {
        if (params?.id) {
            userRequest.getShowcaseEmployerData(params.id).then((res) => {
                console.log(res?.data)
            })
        }
    }, [params])

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            setvideo(acceptedFiles[0])
        }
    }, [acceptedFiles])

    const handleUpload = () => {
        setLoading(true)
        const data = new FormData()
        data.append('document', video)

        userRequest
            .uploadVidFromheart(params?.id, data)
            .then((res) => {
                console.log('res', res.data)
                if (res?.data?.documents) {
                    userRequest
                        .submitVideFromHeartLink(params?.id, {
                            link: res?.data?.documents,
                        })
                        .then((res) => {
                            console.log('sbumitted', res?.data)
                            window.location.replace('/')
                        })
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <div className="promote-bg align-items-center d-flex" style={{ overFlow: 'scroll' }}>
            <div className="container">
                <div className="row position-relative">
                    <div className="col-md-10 offset-md-1">
                        <div className="card create-job-card">
                            <div className="card-body p-3 p-md-5">
                                <input
                                    id="uploader-btn"
                                    type="file"
                                    onChange={(e) => {
                                        setvideo(e.target.files[0])
                                    }}
                                    hidden
                                    accept="video/mp4"
                                />
                                <h4 className="text-muted mb-3">
                                    <strong>Promote your Company</strong>
                                </h4>
                                <div className="mb-4">
                                    <label htmlFor="" className="mb-2">
                                        Candidates are ever more selective in
                                        choosing employers, make sure your
                                        profile showcase your company values
                                        effectively. Record and upload a
                                        heartfelt message encouraging job
                                        seekers to apply to your company.
                                    </label>
                                </div>
                                {!video ? (
                                    <div
                                        className="w-100 my-3"
                                        style={{
                                            height: 300,
                                            border: '2px dashed gray',
                                            borderRadius: 8,
                                        }}
                                        role="button"
                                    >
                                        <div
                                            {...getRootProps()}
                                            className="d-flex w-100 h-100 d-flex"
                                        >
                                            {isDragActive ? (
                                                <div className="m-auto text-center">
                                                    <h6>Drop here</h6>
                                                </div>
                                            ) : (
                                                <div className="m-auto">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="50"
                                                        height="50"
                                                        fill="currentColor"
                                                        class="bi bi-cloud-upload"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
                                                        />
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"
                                                        />
                                                    </svg>
                                                </div>
                                            )}
                                            <input {...getInputProps()} />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="w-100 my-3 d-flex">
                                        <ReactPlayer
                                            url={URL.createObjectURL(video)}
                                            controls
                                            style={{ margin: 'auto' }}
                                        />
                                    </div>
                                )}
                                {video && (
                                    <div className="d-flex">
                                        <button
                                            className="btn btn-primary text-center me-1"
                                            onClick={handleUpload}
                                        >
                                            Submit Video
                                        </button>

                                        <button
                                            className="btn btn-outline-primary text-center"
                                            onClick={() => {
                                                const input =
                                                    document.getElementById(
                                                        'uploader-btn'
                                                    )
                                                input?.click()
                                            }}
                                        >
                                            Change Video
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={loading}
                centered
                contentClassName="bg-transparent border-0"
            >
                <Loading show color="primary" />
            </Modal>
        </div>
    )
}
const mapDispatchToProps = {
    getUser: userActions.getUser,
}
const mapStateToProps = (state) => {
    return {
        User: state.User,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Promote)
