import { useState } from 'react'

const Hoverable = ({ renderOnHover, children, maintainChildren }) => {
    const [isHovered, setIsHovered] = useState(false)
    return (
        <div
            onMouseOver={() => {
                setIsHovered(true)
            }}
            onMouseOut={() => setIsHovered(false)}
            className="w-100 h-100 position-relative"
        >
            <></>
            {isHovered ? renderOnHover() : children}
            {maintainChildren && isHovered && children}
        </div>
    )
}
export default Hoverable
