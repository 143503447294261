import React from 'react'
import Loading from '../../components/reusables/Loading'

function Home() {
    return (
        <div className="d-flex" style={{ height: '100vh' }}>
            <div className="m-auto">
                <Loading show />
            </div>
        </div>
    )
}
export default Home
