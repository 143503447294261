const PaymentSuccess = () => {
    return (
        <div className="w-100 h-100 d-flex">
            <div className="m-auto">
                <div className="d-flex">
                    <img
                        className="mt-5 mx-auto"
                        src="https://www.freeiconspng.com/thumbs/success-icon/success-icon-10.png"
                        style={{ width: 200, height: 200 }}
                        alt=""
                    />
                </div>
                <div className="mt-3 text-center">
                    <h3>Payment Success!</h3>
                    <h6>Ref No.: #78849399349</h6>
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess
