import { combineReducers } from 'redux'
import action from './action'
import Generals from './app/reducers'
import JobFairs, { initJobFair } from './job-fair/jobfairReducers'
import JobMatches, {
    initState as initState_JobMatches,
} from './job-matches/reducer'
import Notifications, {
    initState as initState_Notifications,
} from './notifications/reducer'
import User, { initState as initState_User } from './user/reducer'
const appReducers = combineReducers({
    User,
    Generals,
    JobMatches,
    Notifications,
    JobFairs,
})
const rootReducer = (state, act) => {
    switch (act.type) {
        case action.SIGN_OUT:
            state.User = initState_User
            state.JobMatches = initState_JobMatches
            state.Notifications = initState_Notifications
            state.JobFairs = initJobFair
            return { ...state }
        default:
            return appReducers(state, act)
    }
}
export default rootReducer
