import { Box, Card, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const GridLoader = ({ count }) => {
  return Array.from({ length: count || 12 }).map((_c, index) => {
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={3}
        key={`${_c}-${index}`}
        style={{ minWidth: "260px", maxWidth: "300px" }}
      >
        <Card
          elevation={7}
          style={{
            padding: 20,
            borderRadius: 20,
            minHeight: 400,
          }}
        >
          <Skeleton variant="circle" height={80} width={80} />
          <Skeleton height={50} />
          <Skeleton height={40} />
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={40} />
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <Skeleton
              variant="circle"
              width={50}
              height={50}
              style={{ marginRight: 10 }}
            />
            <Skeleton variant="circle" width={50} height={50} />
          </Box>
        </Card>
      </Grid>
    );
  });
};

export default GridLoader;
