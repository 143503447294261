import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import appActions from '../../../redux/app/actions'
import '../styles.css'
const WelcomeTourModal = ({
    show,
    tourOpen,
    setTourOpen,
    AppState,
    setShowTutorial,
}) => {
    return (
        <Modal className="" centered show={show} size="sm">
            <div className="card rounded p-3 ">
                <h6 className="text-center text-primary">
                    Welcome to Joblu! <br /> Here’s how this works.
                </h6>
                <p className="text-center">
                    Follow this quick tutorial to easily use the web app.
                </p>
                <div className="d-flex mt-2 px-2 justify-content-center">
                    <button
                        className="btn btn-outline-primary text-center me-2"
                        onClick={() => {
                            setShowTutorial(false)
                            setTourOpen(false)
                        }}
                    >
                        Skip
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary text-center me-2"
                        onClick={() => {
                            setTourOpen(true)
                            setShowTutorial(false)
                        }}
                    >
                        Start
                    </button>
                </div>
            </div>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    return { AppState: state.Generals }
}
const mapDispatchToProps = {
    setShowTutorial: appActions.setShowTutorial,
}
export default connect(mapStateToProps, mapDispatchToProps)(WelcomeTourModal)
