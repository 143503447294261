import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import educationIcon from '../../assets/img/educationIcon.svg'
import jobIcon from '../../assets/img/jobIcon.svg'
import languageIcon from '../../assets/img/languageIcon.svg'
import skillsIcon from '../../assets/img/skillsIcon.svg'
import valuesIcon from '../../assets/img/valuesIcon.svg'
import nationalityIcon from '../../assets/joblu-2.0/img/new-icons/nationality.svg'
import overseasExpIcon from '../../assets/joblu-2.0/img/new-icons/overseas-experience.svg'
import employmentHistoryIcon from '../../assets/joblu-2.0/img/new-icons/work-experience.svg'
import {
    PreferencesCard,
    ProfessionalDetailsCard,
} from '../../components/reusables/Profile/Profile'
import UserFiles from '../../components/reusables/Profile/UserFiles'
import { userRequest } from '../../service/requests'
import { profilePlaceholder_Applicant, SECRET } from '../../utils/constants'
import { NameAndMore } from '../explore/components/JobSeekerCard'
import { CustomVideoPlayer } from '../explore/components/VideoPlayer'

const ViewApplicant = ({ selectedUserId }) => {
    const [jobseeker, setJobSeeker] = useState(null)
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const userId = atob(params?.userId)
        const temp = searchParams.get("an")
        if (userId && atob(temp) === SECRET) {
            userRequest.getApplicantDetails(userId).then((res) => {
                if (res) {
                    const applicant = res?.data?.applicantProfiles
                    setJobSeeker(applicant)
                }
            })
        } else {
            navigate('/')
        }
    }, [])

    const professional_details = [
        {
            label: 'Written/Spoken Language',
            value:
                !jobseeker?.languagesSpoken ||
                jobseeker?.languagesSpoken.length === 0
                    ? null
                    : jobseeker.languagesSpoken.map((item, index) =>
                          index === jobseeker.languagesSpoken.length - 1
                              ? `${item}`
                              : `${item}, `
                      ),
            logo: languageIcon,
        },
        {
            label: 'Employment History',
            value: jobseeker?.formerCompanyName,
            subvalue: jobseeker?.formerJobTitle,
            logo: employmentHistoryIcon,
        },
        {
            label: 'Educational Background',
            value: jobseeker?.education,
            logo: educationIcon,
        },
        {
            label: 'Overseas Work Experience',
            value: jobseeker?.singaporeExp
                ? 'Singapore'
                : jobseeker?.overseasExp
                ? 'Other Countries'
                : null,
            logo: overseasExpIcon,
        },
        {
            label: 'Interested to Work In',
            value: !jobseeker?.countriesInterested
                ? null
                : jobseeker.countriesInterested.map((item, index) =>
                      index === jobseeker.countriesInterested.length - 1
                          ? `${item}`
                          : `${item}, `
                  ),
            logo: nationalityIcon,
        },
        { label: 'Job Function', value: jobseeker?.jobFunction, logo: jobIcon },
    ]

    const preferences_details = [
        {
            label: 'Skills and Expertise',
            value: jobseeker?.skills,
            logo: skillsIcon,
        },
        {
            label: 'What I Value',
            value: jobseeker?.importantForYou,
            logo: valuesIcon,
        },
    ]

    return (
        <>
            <div className="container p-3 d-flex justify-content-center">
                <div
                    className="d-flex justify-content-center"
                    id="profileDownloadable"
                >
                    <div className="mt-4">
                        <div className="d-flex border p-3 rounded">
                            <img
                                src={
                                    jobseeker?.profileUrl ||
                                    profilePlaceholder_Applicant
                                }
                                alt=""
                                width="90"
                                height="90"
                            />

                            <NameAndMore jobseeker={jobseeker} />
                        </div>
                        <div className="border p-3 mt-3 rounded">
                            <div className="row mx-0 px-0">
                                {jobseeker?.elevatorPitch && (
                                    <div className="col-4 d-flex">
                                        <CustomVideoPlayer
                                            src={jobseeker.elevatorPitch}
                                            //put resizeMode cover like style
                                            width="100%"
                                            height="100%"
                                            play
                                        />
                                    </div>
                                )}
                                <div className="col">
                                    <ProfessionalDetailsCard
                                        jobseeker={jobseeker}
                                        professional_details={
                                            professional_details
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="border p-3 mt-3 rounded">
                            <PreferencesCard
                                preferences_details={preferences_details}
                            />
                        </div>
                        <div className="mt-3">
                            <UserFiles jobseeker={jobseeker} />
                        </div>
                       
                    </div>
                </div>
            </div>
        </>
    )
}
export default ViewApplicant
