import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import ageIcon from '../../../assets/img/ageIcon.svg'
import educationIcon from '../../../assets/img/educationIcon.svg'
import jobIcon from '../../../assets/img/jobIcon.svg'
import languageIcon from '../../../assets/img/languageIcon.svg'
import locationIcon from '../../../assets/img/locationIcon.svg'
import skillsIcon from '../../../assets/img/skillsIcon.svg'
import CustomChip from '../../../components/reusables/Chip'
import ElevatorPitch from '../../../components/reusables/elevator-pitch/ElevatorPitch'
import CardInExplore from '../../../components/subscription-management/CardInExplore'
import { titleCase } from '../../../utils/helpers'
import '../styles.css'

export function NameAndMore(props) {
    const { jobseeker, currentTab } = props

    const full_name = `${jobseeker?.firstName} ${jobseeker?.lastName}`
    if (!jobseeker) return null
    return (
        <div className="ms-2">
            <h4 className="mb-0">
                {jobseeker?.anonymous && currentTab && currentTab === 'likes'
                    ? 'Job Seeker'
                    : titleCase(full_name)}
            </h4>
            <p>
                <strong>{jobseeker?.preferredTitle}</strong>
            </p>
            <p>{jobseeker?.selfDescription}</p>

            <div className="d-flex flex-column flex-lg-row mt-2">
                <div className="d-flex align-items-center mb-2 mb-lg-0">
                    <img
                        style={{
                            width: 24,
                            height: 24,
                        }}
                        alt=""
                        src={locationIcon}
                        className="me-2"
                    />
                    <p>{jobseeker.currentLocation}</p>
                </div>
                <div className="mx-0 mx-md-3 d-none d-lg-block">|</div>
                <div className="d-flex align-items-center mb-2 mb-lg-0">
                    <img
                        style={{
                            width: 24,
                            height: 24,
                        }}
                        alt=""
                        src={ageIcon}
                        className="me-2"
                    />
                    <p>
                        {' '}
                        {moment().diff(moment(jobseeker.dob), 'years')} Years
                        Old
                    </p>
                </div>
                <div className="mx-0 mx-md-3 d-none d-lg-block">|</div>
                <div className="d-flex align-items-center">
                    <img
                        style={{
                            width: 24,
                            height: 24,
                        }}
                        alt=""
                        src={jobIcon}
                        className="me-2"
                    />
                    <p>
                        {jobseeker?.totalExperienceMin === undefined ||
                        jobseeker?.totalExperienceMin === null
                            ? 'No work experience'
                            : jobseeker?.totalExperienceMin ===
                              jobseeker?.totalExperienceMax
                            ? `${jobseeker?.totalExperienceMin} Years of Experience`
                            : jobseeker?.totalExperienceMin === 20
                            ? `More than ${jobseeker?.totalExperienceMin} Years of Experience`
                            : `${jobseeker?.totalExperienceMin} - ${jobseeker?.totalExperienceMax} Years of Experience`}
                    </p>
                </div>
            </div>
        </div>
    )
}
function JobSeekerCard(props) {
    const {
        jobseeker,
        setExpanded,
        isLast,
        subscription,
        onSwipeFulfilled,
        index,
    } = props
    const isAdvertisement =
        jobseeker?.advertisement && Object.keys(subscription).length === 0
    return (
        <div
            id={isLast && 'jobseeker_card_tutorial'}
            className="row d-flex p-0 m-0 explore-tour-step-5 position-relative border"
            style={{
                height: '50vh',
                overflow: 'hidden',
                background: '#fff',
                boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
            }}
        >
            {!isAdvertisement ? (
                <>
                    <div className="col row p-0  d-flex py-3 m-0">
                        <div className="col-12 row p-0 m-0">
                            <div className="col-12 d-flex ps-4">
                                <div className="">
                                    <ElevatorPitch
                                        jobseeker={jobseeker}
                                        withSwipeAction={true}
                                    />
                                </div>
                                <NameAndMore {...props} currentTab={'likes'} />
                            </div>
                        </div>
                        <div
                            className="col-12 row mb-2 pt-4"
                            style={{ height: '100%' }}
                        >
                            <div className="col-12 col-sm-5 ps-4">
                                <div className="d-flex mb-2">
                                    <img
                                        style={{
                                            width: 16,
                                            height: 16,
                                            marginTop: 3,
                                            marginRight: 8,
                                        }}
                                        alt=""
                                        src={languageIcon}
                                    />
                                    <p>
                                        {_.join(
                                            jobseeker?.languagesSpoken,
                                            ', '
                                        )}
                                    </p>
                                </div>
                                <div className="d-flex mb-2 ">
                                    <img
                                        style={{
                                            width: 16,
                                            height: 16,
                                            marginTop: 3,
                                            marginRight: 8,
                                        }}
                                        alt=""
                                        src={educationIcon}
                                    />
                                    <p className="card-applicant-other-details m-0">
                                        {jobseeker?.education || '-'}
                                    </p>
                                </div>
                                <div className="d-flex mb-1 mt-1 ">
                                    <img
                                        style={{
                                            width: 16,
                                            height: 16,
                                            marginTop: 3,
                                            marginRight: 8,
                                        }}
                                        alt=""
                                        src={skillsIcon}
                                    />
                                    <p className="card-applicant-other-details m-0 w-100">
                                        Skills and Expertise
                                    </p>
                                </div>
                                <div className="w-100 d-flex flex-wrap">
                                    <CustomChip data={jobseeker?.skills} />
                                </div>
                                <div className="d-flex mb-1 mt-2">
                                    <img
                                        style={{
                                            width: 16,
                                            height: 16,
                                            marginTop: 3,
                                            marginRight: 8,
                                        }}
                                        alt=""
                                        src={skillsIcon}
                                    />
                                    <p className="card-applicant-other-details m-0 w-100">
                                        What I Value
                                    </p>
                                </div>
                                <div className="w-100 d-flex flex-wrap">
                                    <CustomChip
                                        data={jobseeker?.importantForYou}
                                    />
                                </div>
                            </div>

                            <div className="col-12 col-sm-7">
                                <p className="card-applicant-other-details m-0 w-100">
                                    About Me
                                </p>
                                <p>{jobseeker?.bio}</p>
                                {/* <p className="card-applicant-other-details m-0 w-100 mt-3">
                            Gallery
                        </p>
                        <div className="">
                            <ApplicantGallery
                                gallery={jobseeker?.gallery || []}
                            />
                        </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-end justify-content-center dotsParentDiv">
                        <div className="w-100 text-center dotsDiv d-flex align-items-center justify-content-center">
                            <span
                                className="d-flex cpointer p-3 mt-3"
                                onClick={() => setExpanded(true)}
                                id={isLast && 'jobseeker_card_dots'}
                            >
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                            </span>
                        </div>
                    </div>
                </>
            ) : (
                <CardInExplore
                    onSwipeFulfilled={onSwipeFulfilled}
                    index={index}
                />
            )}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        subscription: state.User.subscription,
    }
}
export default connect(mapStateToProps, null)(JobSeekerCard)
