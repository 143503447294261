/* * * * * * * * * * * * * * * *
 *  ACTION TYPE naming convention
 *  VERB + '_REQUEST'
 *  VERB + '_SUCCESS'
 *  VERB + '_FAILURE'
 *
 *  ACTION naming convention
 *  verb : type
 * * * * * * * * * * * * * * * */
const action = {
  GET_JOB_APPLICANTS_REQUEST: 'GET_JOB_APPLICANTS_REQUEST',
  GET_JOB_APPLICANTS_SUCCESS: 'GET_JOB_APPLICANTS_SUCCESS',
  GET_JOB_APPLICANTS_FAILURE: 'GET_JOB_APPLICANTS_FAILURE',

  GET_JOB_APPLICANTS_ACTIVE_REQUEST: 'GET_JOB_APPLICANTS_ACTIVE_REQUEST',
  GET_JOB_APPLICANTS_ACTIVE_SUCCESS: 'GET_JOB_APPLICANTS_ACTIVE_SUCCESS',
  GET_JOB_APPLICANTS_ACTIVE_FAILURE: 'GET_JOB_APPLICANTS_ACTIVE_FAILURE',

  GET_JOB_APPLICANTS_SHORTLISTED_REQUEST: 'GET_JOB_APPLICANTS_SHORTLISTED_REQUEST',
  GET_JOB_APPLICANTS_SHORTLISTED_SUCCESS: 'GET_JOB_APPLICANTS_SHORTLISTED_SUCCESS',
  GET_JOB_APPLICANTS_SHORTLISTED_FAILURE: 'GET_JOB_APPLICANTS_SHORTLISTED_FAILURE',

  GET_JOB_APPLICANTS_REJECTED_REQUEST: 'GET_JOB_APPLICANTS_REJECTED_REQUEST',
  GET_JOB_APPLICANTS_REJECTED_SUCCESS: 'GET_JOB_APPLICANTS_REJECTED_SUCCESS',
  GET_JOB_APPLICANTS_REJECTED_FAILURE: 'GET_JOB_APPLICANTS_FAILURE',


  GET_JOB_SEEKERS_REQUEST: 'GET_JOB_SEEKERS_REQUEST',
  GET_JOB_SEEKERS_SUCCESS: 'GET_JOB_SEEKERS_SUCCESS',
  GET_JOB_SEEKERS_FAILURE: 'GET_JOB_SEEKERS_FAILURE',

  GET_JOB_SEEKERS_JOBFAIR_REQUEST: 'GET_JOB_SEEKERS_JOBFAIR_REQUEST',
  GET_JOB_SEEKERS_JOBFAIR_SUCCESS: 'GET_JOB_SEEKERS_JOBFAIR_SUCCESS',
  GET_JOB_SEEKERS_JOBFAIR_FAILURE: 'GET_JOB_SEEKERS_JOBFAIR_FAILURE',
  
  SOFT_RESET: 'SOFT_RESET',

  softReset: () => ({
    type: action.SOFT_RESET
  }),

  getJobApplicants: (jobId) => ({
    type: action.GET_JOB_APPLICANTS_REQUEST,
    payload: jobId,
  }),
  getJobApplicants_Active: (jobId) => ({
    type: action.GET_JOB_APPLICANTS_ACTIVE_REQUEST,
    payload: jobId,
  }),
  getJobApplicants_Shortlisted: (jobId) => ({
    type: action.GET_JOB_APPLICANTS_SHORTLISTED_REQUEST,
    payload: jobId,
  }),
  getJobApplicants_Rejected: (jobId) => ({
    type: action.GET_JOB_APPLICANTS_REJECTED_REQUEST,
    payload: jobId,
  }),
  getJobSeekers: (data) => ({
    type: action.GET_JOB_SEEKERS_REQUEST,
    payload: data,
  }),
  getJobSeekers_JobFair: (id,data) => ({
    type: action.GET_JOB_SEEKERS_JOBFAIR_REQUEST,
    payload: data,
    id: id
  }),
};

export default action;
