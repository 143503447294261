import React from 'react'
import { connect } from 'react-redux'
import SalaryIcon from '../../../assets/img/salaryIcon.svg'
import LocationIcon from '../../../assets/img/locationIcon.svg'
import { formatMoney } from '../../../utils/helpers'
import { useNavigate } from 'react-router-dom'
import { readableDate, titleCase } from '../../../utils/helpers'
import { salaryRanges } from '../../../utils/constants'

function JobsListcard(props) {
    const { User, row, handleSetStatus, tab } = props
    const navigate = useNavigate()
    return (
        <div className="card shadow-sm mt-2">
            <div className="card-body">
                <div className="d-flex">
                    <div className="me-2">
                        <img
                            src={User.user.profileUrl}
                            height={50}
                            width={50}
                            alt=""
                        />
                    </div>
                    <div>
                        {/* aaa {row} bbb */}
                        <h6
                            className="mb-0"
                            onClick={() => {
                                navigate(`${row.id}`, {
                                    state: {
                                        job: row,
                                    },
                                })
                            }}
                        >
                            {titleCase(row?.jobTitle)}
                        </h6>
                        {tab !== 'archived' ? (
                            <>
                                <div className="dropdown">
                                    <button
                                        className="btn btn-link btn-sm dropdown-toggle p-0 text-capitalize"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        // onClick={() => {
                                        //     let action = ''
                                        //     if (tab !== 'active' && tab !== 'paused') {
                                        //         // if (tab === 'paused') action = 'active'
                                        //         if (tab === 'closed') action = 'repost'
                                        //         handleSetStatus(row, action)
                                        //     }
                                        // }}
                                    >
                                        {row?.status}
                                    </button>
                                    {tab !== 'archived' && (
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton1"
                                        >
                                            {tab === 'paused' && (
                                                <>
                                                    <li
                                                        onClick={() =>
                                                            handleSetStatus(
                                                                row,
                                                                'active'
                                                            )
                                                        }
                                                    >
                                                        <a
                                                            className="dropdown-item"
                                                            href="#"
                                                        >
                                                            Active
                                                        </a>
                                                    </li>
                                                </>
                                            )}
                                            {tab === 'active' && (
                                                <>
                                                    <li
                                                        onClick={() =>
                                                            handleSetStatus(
                                                                row,
                                                                'paused'
                                                            )
                                                        }
                                                    >
                                                        <a
                                                            className="dropdown-item"
                                                            href="#"
                                                        >
                                                            Pause
                                                        </a>
                                                    </li>
                                                </>
                                            )}
                                            {tab !== 'closed' &&
                                                tab !== 'archived' && (
                                                    <>
                                                        <li
                                                            onClick={() =>
                                                                handleSetStatus(
                                                                    row,
                                                                    'closed'
                                                                )
                                                            }
                                                        >
                                                            <a
                                                                className="dropdown-item"
                                                                href="#"
                                                            >
                                                                Close
                                                            </a>
                                                        </li>
                                                    </>
                                                )}
                                                {tab === 'closed' && (
                                                    <>
                                                        <li
                                                            onClick={() =>
                                                                handleSetStatus(
                                                                    row,
                                                                    'repost'
                                                                )
                                                            }
                                                        >
                                                            <a
                                                                className="dropdown-item"
                                                                href="#"
                                                            >
                                                                Repost
                                                            </a>
                                                        </li>
                                                    </>
                                                )}
                                        </ul>
                                    )}
                                </div>
                            </>
                        ) : (
                            <a href="#">Archived</a>
                        )}
                    </div>
                </div>
                <div className="mt-2">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <p>
                                <img
                                    src={SalaryIcon}
                                    alt=""
                                    className="me-2"
                                    style={{ height: 24 }}
                                />
                               {
                                    (row?.salaryMin === 1 && row?.salaryMax === 1)
                                    ? salaryRanges.COMPETIIVE_SALARY
                                    : (row?.salaryMin === 2 && row?.salaryMax === 2)
                                    ? salaryRanges.INDUSTRY_STANDARD
                                    :
                                        (row?.salaryMin === 1)
                                        ? `Less than ${formatMoney(row?.salaryMax)}`
                                        : row?.salaryMin < 3000
                                        ? `${formatMoney(row?.salaryMin)} - ${formatMoney(row?.salaryMax)}`
                                        : `More than ${formatMoney(row?.salaryMin)}`
                                }
                            </p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p>
                                <img
                                    src={LocationIcon}
                                    alt=""
                                    className="me-2"
                                    style={{ height: 24 }}
                                />
                                {row?.location || User?.user?.country}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        User: state.User,
    }
}
export default connect(mapStateToProps)(JobsListcard)
