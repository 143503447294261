const jobfairActions = {
    SET_JOB_FAIRS: 'SET_JOB_FAIRS',
    SET_JOB_FAIR_SIGNIN: 'SET_JOB_FAIR_SIGNIN',
    SET_SHOW_BANNER: 'SET_SHOW_BANNER',

    setJobFairs: (payload) => {
        return {
            type: jobfairActions.SET_JOB_FAIRS,
            payload: payload,
        }
    },
    setSignIn: (payload) => ({
        type: jobfairActions.SET_JOB_FAIR_SIGNIN,
        payload: payload,
    }),
    setShowBanner: (payload) => ({
        type: jobfairActions.SET_SHOW_BANNER,
        payload: payload,
    }),
}
export default jobfairActions
